import { sv } from '../../../pure-js/libs/Consts.js'
import { Language, TextKeyWithOnlyText } from '../../../pure-js/types/Antiloop.js'
import { getLanguageCode } from '../hooks/useDefaultLanguage.js'
export type Printable = string | number | undefined

export type Options = {
  boldParams?: boolean
  boldParamsIndex?: number
  splitCharacter?: string
}

export const getFigmaText = (textKey: TextKeyWithOnlyText, language: Language = getLanguageCode()): string => {
  switch (language) {
    // case sv:
    //   return textKey.texts?.sv || textKey.characters || ''
    default:
      return textKey.texts?.sv || textKey.characters || ''
  }
}
