import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import defaultUserIcon from '../resources/images/default_user.png' // Adjust the path as necessary
import { useLogout } from '../hooks/useLogout'
import useAppState from '../hooks/useAppState'
import Box from './Box'
import { getClient } from '../libs/DBApiHandler'
import { useDeepLink } from '../hooks/useDeepLink'
import { useNavigate } from '../hooks/useNavigate'
import { clearAndNavigateOut } from '../utils/loginHelper'

interface UserMenuProps {
  onClientIdSelect: (clientId: string) => void
  initialClientId?: string
}

const UserMenu: React.FC<UserMenuProps> = ({ onClientIdSelect, initialClientId }) => {
  const { state } = useAppState()
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false)
  const [userIcon, setUserIcon] = useState<string>(defaultUserIcon)
  const [clientDetails, setClientDetails] = useState<{ id: string; name: string }[]>([])
  const [selectedClientId, setSelectedClientId] = useState<string>(initialClientId || '')
  const navigate = useNavigate()
  const { clearDeepLink } = useDeepLink()

  const stateFirebaseUser = state.firebaseUser
  const user = state.user

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen)
  }

  const handleClientSelect = (clientId: string) => {
    setSelectedClientId(clientId)
    onClientIdSelect(clientId)
  }

  const logout = useLogout()

  const onLogOut = () => {
    clearAndNavigateOut(clearDeepLink, navigate)
    logout()
  }
  useEffect(() => {
    setUserIcon(stateFirebaseUser?.photoURL || defaultUserIcon)
  }, [state.firebaseUser])

  useEffect(() => {
    const fetchClientDetails = async () => {
      if (user?.authCustomClaims?.clientIds) {
        const clientPromises = user.authCustomClaims.clientIds.map(async (clientId: string) => {
          const client = await getClient(clientId) // Assume getClient fetches the client by ID
          return client ? { id: clientId, name: client.name } : { id: clientId, name: 'Unknown' }
        })

        const details = await Promise.all(clientPromises)
        setClientDetails(details)
      }
    }

    fetchClientDetails().catch((error) => console.error('Error fetching client details:', error))
  }, [user])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node
      if (isUserMenuOpen && !document.getElementById('user-container')?.contains(target)) {
        setIsUserMenuOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isUserMenuOpen])

  return (
    <UserContainer id="user-container" onClick={toggleUserMenu}>
      <img src={userIcon} alt="User Icon" className="user-icon" />
      {isUserMenuOpen && (
        <MenuContainer direction="column" position="absolute">
          <Box direction="row" gap="12px" align="center">
            <img src={userIcon} alt="User Icon" className="user-icon" />
            <Box direction="column" gap="0px">
              <p style={{ fontSize: '14px', margin: 0 }}>{user?.displayName}</p>
              <p style={{ fontSize: '12px', margin: 0 }}>{user?.email}</p>
            </Box>
          </Box>
          <StyledLine />
          {clientDetails.length > 0 ? (
            <ClientSelect
              onClick={(e) => e.stopPropagation()} // Prevent the menu from collapsing
              onChange={(e) => handleClientSelect(e.target.value)} // Handle client selection
              value={selectedClientId} // Set the selected value
            >
              {clientDetails.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </ClientSelect>
          ) : (
            <p>Loading clients...</p>
          )}
          <StyledLine />
          <Logout onClick={onLogOut}>Logga ut</Logout>
        </MenuContainer>
      )}
    </UserContainer>
  )
}

const ClientSelect = styled.select`
  border: 0px;
`

const Logout = styled.p`
  cursor: pointer;
  color: #f44336;
  font-size: 14px;
  border-radius: 4px;
  margin: 0;
  padding: 8px;
  font-size: 14px;

  &:hover {
    background: #f44336;
    color: white;
  }
`

const StyledLine = styled.hr`
  width: 100%;
  border-color: rgba(255, 255, 255, 0.1);
  margin: 10px 0;
  height: 2px;
`

const MenuContainer = styled(Box)`
  z-index: 9999; /* Higher value ensures it stays on top */
  display: flex;
  width: auto;
  align-items: flex-start;
  gap: 2px;
  border-radius: 8px;
  background: #171231;
  right: 0px;
  top: 0px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
`

const UserContainer = styled(Box)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 9999;
`

export default UserMenu
