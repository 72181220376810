import { HardCodedTexts, DeprecatedTexts } from '../../../pure-js/libs/Texts'
import { bigSpacing, reallyBigSpacing } from '../enums/Spacings'
import { getFigmaText } from '../libs/TextRepository'
import Box from './Box'
import FigmaText from './FigmaText'
import { Layout } from './Layout'

export default function ErrorBoundaryView() {
  return (
    <Layout>
      <Box fullWidth fullPadding align="center" spacing={bigSpacing} fullHeight>
        <Box top spacing={reallyBigSpacing}>
          {'A wonderful Logo tp be creatd by our lovely Product Owner'}
        </Box>
        <Box top>
          <FigmaText
            textKey={DeprecatedTexts.onboardingStartPageLoginPageHeader}
            text="Course Registration is currently down for maintenence"
          />
        </Box>
        <Box top>
          <FigmaText
            textKey={DeprecatedTexts.onboardingStartPageLoginPageSecondaryText}
            text={getFigmaText(HardCodedTexts.errorMaintenanceDescription)}
          />
        </Box>
      </Box>
    </Layout>
  )
}
