import { Outlet } from 'react-router-dom'
import useClientWindowApi from './hooks/useClientWindowApi'
import useOnLoggedIn from './hooks/useOnLoggedIn'
import Box from './components/Box'

const AppContainer = () => {
  useClientWindowApi()
  useOnLoggedIn()

  return (
    <Box fullWidth fullHeight>
      <Outlet />
    </Box>
  )
}

export default AppContainer
