import React from 'react'
import styled from '@emotion/styled'
import Box from './Box'

interface DashboardCardProps {
  icon: React.ReactElement
  title: string
  value: string | number
  subtitle?: string
}

const DashboardCard: React.FC<DashboardCardProps> = ({ icon, title, value, subtitle = '' }) => {
  return (
    <DashboardCardInstance>
      <CardContent>
        <DashboardCardHeader>
          <IconWrapper>{icon}</IconWrapper>
          <HeaderText>{title}</HeaderText>
        </DashboardCardHeader>
        <ValueContainer>
          <DashboardCardValue>{value}</DashboardCardValue>
          {subtitle && <DashboardCardSubTitle>{subtitle}</DashboardCardSubTitle>}
        </ValueContainer>
      </CardContent>
    </DashboardCardInstance>
  )
}

// Styled Components
const DashboardCardInstance = styled(Box)`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  padding: 1rem;
  flex: 1;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(224, 184, 215, 0.31);
  @media (max-width: 768px) {
    background: none;
    border: none;
    flex: 1 1 100%;
    min-width: 100%;
  }
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const DashboardCardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const IconWrapper = styled.div`
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`

const HeaderText = styled.h3`
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
`

const ValueContainer = styled.div`
  text-align: center;
`

const DashboardCardValue = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  @media (max-width: 768px) {
    margin: 0;
    font-size: 1.2rem;
  }
`

const DashboardCardSubTitle = styled.p`
  font-size: 1rem;
  color: #ccc;
  margin: 0;
  @media (max-width: 768px) {
    display: none;
  }
`

export default DashboardCard
