import React, { useEffect, useState } from 'react'
import '../resources/css/Dashboard.css'
import DashboardCard from '../components/DashboardCard'
import userIcon from '../resources/images/User.png' // Adjust the path as necessary
import moneyIcon from '../resources/images/Money.png' // Adjust the path as necessary
import ActivityList from '../components/ActivityList'
import EmptyStateImg from '../resources/images/EnrollmentEmpty.png'
import { ViewList } from '@mui/icons-material'

import { getActivitiesForClient } from '../libs/DBApiHandler'
import { Activity, Enrollment } from '../../../pure-js/types/GrooverTypes'
import { getEnrollmentsForClient } from '../libs/CloudFunctionsApiHandler'

import UserMenu from '../components/UserMenu'
import useAppState from '../hooks/useAppState'
import { getAuth } from 'firebase/auth'
import {
  getAllEnrollmentsCount,
  getAllPaidRevenueForRegistration,
  getAllRevenueForRegistration,
  getAverageActiveCourseFillRate,
  getPaidRegistrationRatio
} from '../utils/enrollmentUtils'
import Box from '../components/Box'
import { Texts } from '../../../pure-js/libs/Texts'
import FigmaText from '../components/FigmaText'

const Dashboard: React.FC = () => {
  const { state } = useAppState()

  const [clientId, setClientId] = useState<string>(() => {
    // Initialize from localStorage, fallback to empty string
    return localStorage.getItem('selectedClientId') || ''
  })
  const [error, setError] = useState<Error | null>(null)

  const [loading, setLoading] = useState(false)
  const [enrollments, setEnrollments] = useState<Enrollment[]>([])
  const [activities, setActivities] = useState<Activity[]>([])
  const [activitiesLoaded, setActivitiesLoaded] = useState<boolean>(false)

  const auth = getAuth()
  const currentUser = auth.currentUser

  const hasAccess = () => {
    const clientIds = state.user?.authCustomClaims?.clientIds ?? []
    return clientIds.length > 0 && clientIds.every((id) => typeof id === 'string' && id.trim() !== '')
  }

  // this is not fetching data now. It just sets connection and observe the database
  // check activities.isLoading and activities.status to see if data is fetched
  // then you can use activities.data
  const _activities = getActivitiesForClient(clientId)

  useEffect(() => {
    if (!clientId) return
    if (!_activities.isLoading && _activities.status == 'success') {
      setActivities(_activities.data as Activity[])
      setActivitiesLoaded(true)
    }
  }, [clientId, _activities])

  useEffect(() => {
    const fetchData = async () => {
      if (!state.user) {
        console.warn('No current user found')
        return
      }

      if (!hasAccess()) {
        return
      }

      setLoading(true) // Set loading to true at the beginning

      try {
        // If we have no clientId, we need to fetch the user data to get the clientIds
        if (!clientId) {
          const userClientIds = state.user.authCustomClaims?.clientIds || []

          if (userClientIds.length > 0) {
            // Use the first clientId
            setClientId(userClientIds[0])
            const req = { clientId: userClientIds[0] } // Replace with your actual request shape
            const _enrollments = await getEnrollmentsForClient(req, state) // Call your backend function

            setEnrollments(_enrollments.filter((x) => x.status != 'deleted'))
          }
        } else {
          // Fetch enrollments for the existing clientId
          const req = { clientId } // Replace with your actual request shape
          const _enrollments = await getEnrollmentsForClient(req, state) // Call your backend function

          setEnrollments(_enrollments.filter((x) => x.status != 'deleted'))
        }
      } catch (err) {
        console.error('Error fetching data: ', err)
        setError(err as Error)
      } finally {
        setLoading(false) // Set loading to false at the end
      }
    }

    fetchData()
  }, [state.user, currentUser?.uid, state, clientId]) // observe authUser, currentUser.uid, state, and clientId

  const handleClientIdSelect = (newClientId: string) => {
    setClientId(newClientId)
    localStorage.setItem('selectedClientId', newClientId)
  }

  return (
    <div className="mainContainer">
      {/* <Menu activeItem="dashboard" /> */}{' '}
      {/* TODO: Add a CSS that changes the design when in mobile (top menu instead) */}
      <UserMenu onClientIdSelect={handleClientIdSelect} initialClientId={clientId} />
      <div className="PageContainer">
        {!hasAccess() ? (
          <Box fullWidth align="center" direction="column" gap="24px" justify="center" style={{ minHeight: '80vh' }}>
            <FigmaText textKey={Texts.adminGeneralNoAccessHeader} />
            <FigmaText textKey={Texts.adminGeneralNoAccessText} />
            <img src={EmptyStateImg} alt="No Registrations" className="empty-state-img" />
          </Box>
        ) : (
          <div className="dashboard-container">
            <div className="dashboard-header">
              <div className="dashboard-period">
                <span>Period</span>
                <select>
                  <option>VT 2025</option>
                </select>
              </div>
              <Box>
                <a
                  href={`https://app.coursely.se/activities/${clientId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  <ViewList />
                  <FigmaText textKey={Texts.adminGeneralOpenPublicActivityPage} />
                </a>
              </Box>
            </div>

            {loading ? (
              <Box fullWidth align="center">
                <div className="spinner"></div>
              </Box>
            ) : (
              <>
                <div className="dashboard-cards">
                  <DashboardCard
                    icon={<img src={userIcon} alt="User Icon" />}
                    title="Anmälningar"
                    value={getAllEnrollmentsCount(enrollments)}
                    subtitle={'Beläggning: ' + getAverageActiveCourseFillRate(enrollments, activities) + '%'}
                  />
                  <DashboardCard
                    icon={<img src={moneyIcon} alt="Money Icon" />}
                    title="Totalt värde"
                    value={getAllRevenueForRegistration(enrollments, true) + ' SEK'}
                    subtitle={`Inkl. ej godkända: ${getAllRevenueForRegistration(enrollments, false)} SEK`}
                  />
                  <DashboardCard
                    icon={<img src={moneyIcon} alt="Money Icon" />}
                    title="Inbetalt"
                    value={`${getAllPaidRevenueForRegistration(enrollments)} SEK`}
                    subtitle={`${getPaidRegistrationRatio(enrollments)}`}
                  />
                </div>

                <div className="coursesContainer">
                  <ActivityList
                    activities={activities as Activity[]}
                    activitiesLoaded={activitiesLoaded}
                    enrollments={enrollments as Enrollment[]}
                    clientId={clientId}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Dashboard
