import React from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { RootPage } from '../pages/SignInPage'
import useAppState from '../hooks/useAppState'
import { useDeepLink } from '../hooks/useDeepLink'

const NavigationPublicRoutes: React.FC = () => {
  const { state } = useAppState()
  const location = useLocation()
  const { getDeepLink, saveDeepLink } = useDeepLink()

  const path = location.pathname
  const search = location.search

  if (!getDeepLink() && !state?.isLoggedIn && path && path.split('/').length > 2) {
    saveDeepLink(path, search)
  }

  return (
    <>
      <Routes>
        <Route path={RoutePath.ROOT} Component={RootPage} />
        <Route path="*" element={<Navigate to={RoutePath.ROOT} />} />
      </Routes>
    </>
  )
}

export default NavigationPublicRoutes
