import { PreferredLoginMethod, SignInState, SignInSteps } from '../../../pure-js/types/SignInTypes'
import { State } from '../hooks/useAppState'
export type SignInPageProps = {
  onLogin?: (state: State) => unknown
  signInState?: Partial<SignInState>
}

export const DEFAULT_SIGN_IN_STATE: SignInState = {
  data: {
    email: ''
  },
  preferredLoginMethod: PreferredLoginMethod.MAIL,
  step: SignInSteps.LANDING
}
