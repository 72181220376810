import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { ENTER_KEYCODE } from '../../../pure-js/libs/Consts'
import { SignInViewProps } from '../../../pure-js/types/SignInTypes'
import { smallSpacing } from '../enums/Spacings'
import { useForm } from '../hooks/useForm'
import { getFigmaText } from '../libs/TextRepository'
import { DeprecatedTexts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import FigmaTextLoader from './FigmaTextLoader'
import { TextFieldFormExperimental } from './TextFieldForm'

const message = getFigmaText(DeprecatedTexts.onboardingOtpPageInputOtpCodeErrorWrongCode)
export const ValidationSchema = Yup.object().shape({
  code: Yup.string()
    .required(message)
    .typeError(message)
    .length(6, message)
    .test('isNumber', message, (text) => {
      return parseInt(text || '') > 0
    })
})

export default function SignInEnterCodeForm(props: SignInViewProps) {
  const { signInState } = props
  const { data } = signInState
  const { code = '', createAuthenticationTokenForLoginTokenResponse } = data
  const [error, setError] = useState(false)

  const _onClick = (values) => props.onPressContinue({ ...signInState, data: { ...signInState.data, ...values } })

  const { control, handleSubmit } = useForm(ValidationSchema, { defaultValues: { code } })

  const onSubmit = handleSubmit(_onClick)

  useEffect(() => {
    if (createAuthenticationTokenForLoginTokenResponse?.success === false) setError(true)
  }, [createAuthenticationTokenForLoginTokenResponse])

  return (
    <form onSubmit={_onClick}>
      <Box align="center" fullWidth>
        <Controller
          control={control}
          name="code"
          render={(props) => (
            <Box fullWidth>
              <TextFieldFormExperimental
                {...props}
                field={{
                  ...props.field,
                  onKeyDown: (e) => {
                    setError(false)
                    e.keyCode === ENTER_KEYCODE && onSubmit(e)
                  }
                }}
                headerText={getFigmaText(DeprecatedTexts.onboardingOtpPageInputOtpCodeHeader)}
                hasError={!!error}
              />
              {error && !props.fieldState.error && (
                <Box fullWidth align="flex-end">
                  <FigmaText textKey={DeprecatedTexts.onboardingStartPageInputEmailErrorWrongFormat} text={message} />
                </Box>
              )}
            </Box>
          )}
        />

        <Box top fullWidth spacing={smallSpacing}>
          <Button fullWidth onClick={onSubmit} textKey={DeprecatedTexts.onboardingOtpPageCtaPrimaryVerifyCode}>
            <FigmaTextLoader
              textKey={DeprecatedTexts.onboardingOtpPageCtaPrimaryVerifyCode}
              loading={props.isLoading}
            />
          </Button>
        </Box>
      </Box>
    </form>
  )
}
