import styled from '@emotion/styled'
import React from 'react'
import { getFigmaText } from '../libs/TextRepository'
import { DeprecatedTexts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import ButtonBase, { ButtonBaseProps } from './ButtonBase'
import { ButtonProps } from './ButtonPrimary'
import FigmaTextLoader from './FigmaTextLoader'

const ButtonTertiary: React.FC<ButtonProps> = (props) => {
  const { children, className, textKey, text, ...buttonProps } = props

  return (
    <StyledButton className={className} {...buttonProps} variant="contained" color={'tiertary' as any}>
      <Box align="center" fullWidth>
        {children || (
          <FigmaTextLoader
            textKey={DeprecatedTexts.dashboardCtaVerifyRemoveUser}
            text={text || (textKey ? getFigmaText(textKey) : undefined)}
            loading={props.loading}
          />
        )}
      </Box>
    </StyledButton>
  )
}

const StyledButton = styled(ButtonBase)<ButtonBaseProps>``

export default ButtonTertiary
