import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Snackbar, Button, IconButton, Menu, MenuItem } from '@mui/material'
import Box from '../components/Box'
import { smallSpacing } from '../enums/Spacings'
import toast from 'react-hot-toast'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import moneyIcon from '../resources/images/Money.png'
import EmptyStateImg from '../resources/images/EnrollmentEmpty.png'
import editIcon from '../resources/svg/editIcon.svg'
import lucide_circle_check from '../resources/svg/lucide_circle-check.svg'
import lucide_circle_check_green from '../resources/svg/lucide_circle-check_green.svg'
import userIcon from '../resources/images/User.png'
import MoreVert from '@mui/icons-material/MoreVert'
import DashboardCard from '../components/DashboardCard'
import useAppState from '../hooks/useAppState'
import { getEnrollmentsForClient, sendEnrollmentEmail, upsertEnrollment } from '../libs/CloudFunctionsApiHandler'
import { Activity, Enrollment, EnrollmentUserRequest } from '../../../pure-js/types/GrooverTypes'

import '@material/data-table/dist/mdc.data-table.css'
import '../resources/css/Dashboard.css'
import '../resources/css/EnrollmentsList.css'
import {
  getAllEnrollmentsCount,
  getAllPaidRevenueForRegistration,
  getAllRevenueForRegistration,
  getEnrollmentPrice,
  getPaidRegistrationRatio,
  isEnrollmentPaid
} from '../utils/enrollmentUtils'
import dayjs from 'dayjs'
import { getActivity } from '../libs/DBApiHandler'
import styled from '@emotion/styled'
import EnrollmentType from '../components/EnrollmentType'
import PaymentStatus from '../components/PaymentStatus'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'

const EnrollmentsPage: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [enrollments, setEnrollments] = useState<Enrollment[]>([])
  const [loading, setLoading] = useState(true)
  const [activity, setActivity] = useState<Activity | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedRow, setSelectedRow] = useState<Enrollment | null>(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const navigate = useNavigate()
  const state = useAppState()

  const { activityId, clientId } = useParams<{ activityId: string; clientId: string }>()

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, row: Enrollment) => {
    setAnchorEl(event.currentTarget)
    setSelectedRow(row)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setSelectedRow(null)
  }

  const redirectToEditActivity = () => {
    navigate(`/editActivity/${activityId}`)
  }
  const handleApproveAttendee = () => {
    if (selectedRow) {
      updateAttendeeStatus('confirmed')
      handleMenuClose()
    }
  }

  const updateAttendeeStatus = async (newStatus: 'pending' | 'confirmed' | 'active' | 'cancelled' | 'deleted') => {
    if (selectedRow) {
      setLoading(true)
      handleMenuClose()
      selectedRow.status = newStatus

      if (newStatus === 'confirmed') {
        const response = await sendEnrollmentEmail(
          { enrollmentId: selectedRow.id, type: 'registration_approved' },
          state.state
        )
        if (!response.success) {
          console.error('Payment confirmed email error', response)
          return // don't upsert enrollment with new status
        }
      }

      // Call the upsertEnrollment function to save the changes
      saveEnrollment(selectedRow)

      // Show the Snackbar
      setSnackbarOpen(true)

      // Remove the row from enrollments if status is 'deleted'
      if (newStatus === 'deleted') {
        setEnrollments((prevEnrollments) => prevEnrollments.filter((enrollment) => enrollment.id !== selectedRow.id))
      }

      setLoading(false)
    }
  }

  const saveEnrollment = (enrollment: Enrollment) => {
    // Call the upsertEnrollment function to save the changes
    upsertEnrollment(enrollment, state.state)
      .then((response) => console.log('Enrollment status updated successfully', response))
      .catch((error) => {
        console.error('Error updating enrollment status', error)
      })
  }

  const handleApprovePayment = async (enrollment, isMainUser: boolean, status: string) => {
    console.log('Updating payment status:', enrollment, status)

    // changing into pending means refunding (in alpha)
    const isRefund = status == 'pending'
    const price = isRefund ? -getEnrollmentPrice(enrollment) : getEnrollmentPrice(enrollment)

    if (!enrollment.payments) enrollment.payments = []
    // add manual payment/refund
    enrollment.payments.push({
      paymentDate: dayjs().toISOString(),
      currency: 'SEK',
      totalAmount: price,
      paymentMethod: 'manual',
      source: 'manual',
      type: isRefund ? 'refund' : 'pay'
    })

    // Update the courseUserRegistrations state with the updated attendee
    setEnrollments((prevRegistrations) =>
      prevRegistrations.map((registration) =>
        registration.id === enrollment.id ? { ...registration, payments: [...enrollment.payments] } : registration
      )
    )

    // Show the Snackbar
    setSnackbarOpen(true)

    if (status === 'confirmed') {
      console.log('Sending payment confirmed email for enrollment:', enrollment.id)
      const response = await sendEnrollmentEmail(
        { enrollmentId: enrollment.id, type: 'payment_confirmed' },
        state.state
      )
      if (!response.success) {
        console.error('Payment confirmed email error', response)
        return // don't upsert enrollment with new status
      }
    }

    // Call the upsertEnrollment function to save the changes
    upsertEnrollment(enrollment, state.state)
      .then((response) => console.log('Payment status updated successfully', response))
      .catch((error) => console.error('Error updating payment status', error))
  }

  const handleApprovePaymentAndCloseMenu = (status: string) => {
    if (selectedRow) {
      handleApprovePayment(selectedRow, true, status)
      handleMenuClose()
    }
  }

  // Get all course user registrations by registration id
  useEffect(() => {
    const fetchCourseUserRegistrations = async () => {
      try {
        if (!clientId) {
          throw new Error('Client ID is undefined')
        }
        const req = { clientId: clientId } // Replace with actual registration ID and customer ID
        const enrollments = await getEnrollmentsForClient(req, state.state)
        const filteredEnrollments = enrollments
          .filter((enrollment) => enrollment.activities.some((activity) => activity.id === activityId))
          .filter((enrollment) => enrollment.status !== 'deleted')
        setEnrollments(filteredEnrollments)

        setLoading(false)
      } catch (err) {
        console.error('Error fetching course user registrations:', err)
        setLoading(false)
      }
    }

    fetchCourseUserRegistrations()
  }, [state.state, clientId, activityId])

  // Get activity
  useEffect(() => {
    const fetchActivity = async () => {
      if (activityId) {
        const _activity = await getActivity(activityId)
        if (_activity) {
          setActivity(_activity)
        }
      }
    }
    fetchActivity()
  }, [activityId])

  // Add resize handler
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleReturn = () => {
    navigate(-1) // Navigate back to the previous page
  }

  const getEnrollmentDate = (enrollment: Enrollment) => {
    return (
      <Box>
        {new Date(enrollment.createdAt).toLocaleDateString('sv-SE', {
          day: 'numeric',
          month: 'numeric'
        })}
      </Box>
    )
  }

  const getEnrollmentContactInfo = (enrollmentUser: EnrollmentUserRequest) => {
    return (
      <Box direction="row" fullWidth align="center" gap="8px">
        <EnrollmentType partnerType={enrollmentUser.type} />
        <EnrollerTitle> {enrollmentUser.name} </EnrollerTitle>
        <EnrollerEmail
          title={enrollmentUser.email}
          onClick={() => {
            navigator.clipboard.writeText(enrollmentUser.email)
            toast.success('Email kopierad till urklipp', { duration: 2000 })
          }}
          style={{ cursor: 'pointer' }}
        >
          {enrollmentUser.email}
        </EnrollerEmail>
        <EnrollerPhone> {enrollmentUser.phone} </EnrollerPhone>
      </Box>
    )
  }

  const renderEnrollmentUserInfo = (enrollment: Enrollment, index: number, isMobile: boolean) => {
    const enrollmentRow = (
      <Box direction="row" fullWidth key={`${enrollment.id}-main`}>
        {
          //
          // Mobile view
          //
          isMobile ? (
            <Box id="Mobile_EnrollmentUsers" direction="column" fullWidth gap="8px">
              <Box id="Mobile_EnrollmentMainUser" direction="row" align="center" justify="space-between" fullWidth>
                <EnrollerTitle> {enrollment.mainUser.name} </EnrollerTitle>
                <EnrollmentType partnerType={enrollment.mainUser.type} />
              </Box>
              {enrollment.partnerUser && (
                <Box id="Mobile_EnrollmentPartnerUser" direction="row" align="center" justify="space-between" fullWidth>
                  <EnrollerTitle> {enrollment.partnerUser.name} </EnrollerTitle>
                  <EnrollmentType partnerType={enrollment.partnerUser.type} />
                </Box>
              )}
            </Box>
          ) : (
            //
            // Desktop view
            //
            <Box id="Web_EnrollmentUsers" direction="column" fullWidth gap="8px">
              {getEnrollmentContactInfo(enrollment.mainUser)}
              {enrollment.partnerUser && getEnrollmentContactInfo(enrollment.partnerUser)}
            </Box>
          )
        }
      </Box>
    )

    return (
      <EnrollmentRow fullWidth align="flex-start" direction="row" key={enrollment.id}>
        <Box direction="column" fullWidth gap="8px">
          <MetaInfoContainer direction="row">
            {getEnrollmentDate(enrollment)}
            <div className={`cell status ${enrollment.status.toLowerCase()}`}>
              {window.innerWidth > 350
                ? enrollment.status === 'pending'
                  ? ' Ej godkänd'
                  : ' Godkänd'
                : enrollment.status === 'pending'
                  ? ' Ej OK'
                  : ' OK'}
            </div>
            <PaymentStatus enrollment={enrollment} />
          </MetaInfoContainer>
          {[enrollmentRow]}
        </Box>
        <IconButton
          onClick={(event) => handleMenuOpen(event, enrollment)}
          style={{ margin: '2px', height: 'fit-content', padding: '2px' }}
        >
          <MoreVert style={{ color: 'white' }} />
        </IconButton>
      </EnrollmentRow>
    )
  }

  return (
    <div className="mainContainer">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        message="Update successful"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <div className="PageContainer">
        <div className="dashboard-container">
          <Box direction="row" align="center" gap="16px" fullWidth>
            <ArrowBackIcon onClick={handleReturn} style={{ cursor: 'pointer' }} />
            <Box direction="row" align="flex-start" className="boxColumn">
              <ActivityText>{activity?.name}</ActivityText>
              <Button
                startIcon={<img src={editIcon} style={{ width: '1em', height: '1em' }} />}
                onClick={redirectToEditActivity}
                className="button"
                sx={{ minWidth: 'fit-content' }}
              >
                {getFigmaText(Texts.adminGeneralCtactaEditActivity)}
              </Button>
            </Box>
          </Box>
          {loading ? (
            <div className="loading">
              <div className="spinner"></div>
            </div>
          ) : enrollments.length === 0 ? (
            <div className="no-registrations">
              <img src={EmptyStateImg} alt="No Registrations" className="empty-state-img" />
              <p>Inga anmälningar än</p>
            </div>
          ) : (
            <>
              <div className="dashboard-cards">
                <DashboardCard
                  icon={<img src={userIcon} alt="User Icon" />}
                  title="Anmälningar"
                  value={getAllEnrollmentsCount(enrollments)}
                />
                <DashboardCard
                  icon={<img src={userIcon} alt="User Icon" />}
                  title="Kursvärde"
                  value={getAllRevenueForRegistration(enrollments, true) + ' SEK'}
                  subtitle={`Inkl. ej godkända: ${getAllRevenueForRegistration(enrollments, false)} SEK`}
                />
                <DashboardCard
                  icon={<img src={moneyIcon} alt="Money Icon" />}
                  title="Betalda"
                  value={`${getAllPaidRevenueForRegistration(enrollments)} SEK`}
                  subtitle={`${getPaidRegistrationRatio(enrollments)}`}
                />
              </div>
              <div className="attendee-list">
                {enrollments
                  .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                  .flatMap((enrollment, index) => renderEnrollmentUserInfo(enrollment, index, isMobile))}
              </div>
            </>
          )}
        </div>
      </div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} classes={{ paper: 'menu-custom' }} onClose={handleMenuClose}>
        {selectedRow && (
          <>
            {selectedRow.status !== 'confirmed' && (
              <MenuItem onClick={() => handleApproveAttendee()}>
                <img src={lucide_circle_check_green} style={{ marginRight: '8px' }} />
                Godkänn deltagare
              </MenuItem>
            )}
            {selectedRow.status === 'confirmed' && !isEnrollmentPaid(selectedRow) && (
              <MenuItem onClick={() => handleApprovePaymentAndCloseMenu('confirmed')}>
                <img src={lucide_circle_check_green} style={{ marginRight: '8px' }} />
                Markera som betald
              </MenuItem>
            )}
            {isEnrollmentPaid(selectedRow) && (
              <MenuItem onClick={() => handleApprovePaymentAndCloseMenu('pending')}>
                <img src={lucide_circle_check} style={{ marginRight: '8px', color: 'red' }} />
                Markera som obetald
              </MenuItem>
            )}
            <div style={{ borderTop: '1px solid lightgray', margin: '8px 0' }}></div>
            <MenuItem onClick={() => updateAttendeeStatus('deleted')} style={{ color: 'red' }}>
              <CloseIcon />
              Radera anmälning
            </MenuItem>
          </>
        )}
      </Menu>
    </div>
  )
}

const ActivityText = styled.h4`
  margin-right: 16px;
`

const MetaInfoContainer = styled(Box)`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 4px 8px;
  border-radius: 6px;
  width: 100%;
`

const EnrollerTitle = styled.p`
  font-size: 1.2rem;
  margin: 0;
  padding: 0px 8px;
  @media (min-width: 768px) {
    min-width: min(20vw, 200px);
    max-width: min(20vw, 200px);
  }
`

const EnrollerEmail = styled.p`
  font-size: 1rem;
  margin: 0;
  padding: 0px 8px;
  min-width: min(20vw, 200px);
  max-width: min(20vw, 200px);
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const EnrollerPhone = styled.p`
  font-size: 1rem;
  margin: 0;
  padding: 0px 8px;
  min-width: 20vw;
`

const EnrollmentRow = styled(Box)`
  margin: 8px 0;
  background-color: rgba(255, 255, 255, 0.05);
  padding: ${smallSpacing};
  border-radius: 10px;
`

export default EnrollmentsPage
