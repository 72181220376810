import styled from '@emotion/styled'
import { DeprecatedTexts } from '../../../pure-js/libs/Texts'
import { SignInViewProps } from '../../../pure-js/types/SignInTypes'
import config from '../Config'
import { BorderRadixes } from '../enums/BorderRadixes'
import { reallyBigSpacing, smallSpacing } from '../enums/Spacings'
import { LoginConatinerWidth } from '../libs/HardCodedSizes'
import Box from './Box'
import FigmaText from './FigmaText'
import { Layout } from './Layout'
import { SignInLandingLogo } from './SignInLandingCourselyLogo'
import { GoogleButton } from './GoogleButton'

export default function SignInLanding(props: SignInViewProps) {
  return (
    <Layout>
      <ResponsiveBox>
        <Box fullWidth align="center" className="mainContainerLogin">
          <Box top spacing={reallyBigSpacing}>
            <SignInLandingLogo />
          </Box>
          <Box top spacing={reallyBigSpacing}>
            <Container fullWidth align="center" right left spacing={reallyBigSpacing}>
              <Box top spacing={smallSpacing}>
                <FigmaText textKey={DeprecatedTexts.onboardingStartPageLoginPageSectionHeader} />
              </Box>
              {config.enableGoogleLogin && (
                <Box top spacing={smallSpacing} fullWidth>
                  <GoogleButton onClick={props.onLoginWithGoogle} />
                </Box>
              )}
              <Box top spacing={smallSpacing} />
            </Container>
          </Box>
        </Box>
      </ResponsiveBox>
    </Layout>
  )
}

const Container = styled(Box)`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: ${BorderRadixes.moderate};
  width: ${LoginConatinerWidth}px;

  @media (max-width: 768px) {
    width: 90%;
    min-width: 280px;
    margin: 0 auto;
    padding: 20px;
  }
`

const ResponsiveBox = styled(Box)`
  width: 100%;
  padding: 20px;

  @media (max-width: 768px) {
    .mainContainerLogin {
      padding: 10px;
    }

    svg {
      width: 80%;
      height: auto;
    }
  }
`
