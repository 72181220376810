import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import StylesProvider from '@mui/styles/StylesProvider'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter } from 'react-router-dom'

import { Context, useAppStateContext } from './hooks/useAppState'
import './libs/Fonts'
import theme from './libs/theme'
import ErrorBoundaryProvider from './components/ErrorBoundaryProvider'
import { Toaster } from 'react-hot-toast'

import { Helmet } from './components/Helmet'

import PrivateRoutes from './components/NavigationPrivateRoutes'
import PublicRoutes from './components/NavigationPublicRoutes'
import { OnMountApp } from './hooks/useOnMountApp'
import { SNACK_BAR_PROPS } from '../../pure-js/libs/Consts'

const App = () => {
  const appContext = useAppStateContext()
  return (
    <ErrorBoundaryProvider>
      <StylesProvider injectFirst>
        <SnackbarProvider {...SNACK_BAR_PROPS}>
          <Context.Provider value={appContext}>
            <BrowserRouter>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <Helmet titleTemplate="%s - Groover" />
                  <OnMountApp />

                  <Toaster position="top-center" reverseOrder={false} toastOptions={{ duration: 2000 }} />
                  {appContext?.state.isLoggedIn ? <PrivateRoutes /> : <PublicRoutes />}
                </ThemeProvider>
              </StyledEngineProvider>
            </BrowserRouter>
          </Context.Provider>
        </SnackbarProvider>
      </StylesProvider>
    </ErrorBoundaryProvider>
  )
}

export default App
