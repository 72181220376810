import styled from '@emotion/styled'

interface EnrollmentTypeProps {
  partnerType: 'leader' | 'follower'
}

const EnrollmentType: React.FC<EnrollmentTypeProps> = ({ partnerType }) => {
  const typeConfig = {
    leader: { color: '#4A90E2', text: 'Ledare' },
    follower: { color: '#F5A623', text: 'Följare' },
    both: { color: '#188C09', text: 'Båda' }
  }

  return (
    <EnrollerType style={{ backgroundColor: typeConfig[partnerType].color }}>
      {typeConfig[partnerType].text}
    </EnrollerType>
  )
}

const EnrollerType = styled.p`
  border-radius: 12px;
  padding: 2px 8px;
  font-size: 0.8rem;
  margin: 0;
  height: fit-content;
`

export default EnrollmentType
