interface DeepLink {
  path: string
  search?: string
}

export const useDeepLink = () => {
  const STORAGE_KEY = 'DEEP_LINK' // Use the same key consistently

  const getDeepLink = (): DeepLink | null => {
    try {
      const stored = localStorage.getItem(STORAGE_KEY)
      return stored ? JSON.parse(stored) : null // Parse JSON string to object
    } catch (error) {
      console.error('Failed to parse deep link from localStorage:', error)
      return null
    }
  }

  const saveDeepLink = (path: string, search?: string) => {
    try {
      const deepLink: DeepLink = { path, search }
      localStorage.setItem(STORAGE_KEY, JSON.stringify(deepLink)) // Convert object to JSON string
      console.log('Deep link saved:', deepLink)
    } catch (error) {
      console.error('Failed to save deep link to localStorage:', error)
    }
  }

  const clearDeepLink = () => {
    try {
      localStorage.removeItem(STORAGE_KEY)
      console.log('Deep link cleared')
    } catch (error) {
      console.error('Failed to clear deep link from localStorage:', error)
    }
  }

  return {
    getDeepLink, // Fetch the deep link immediately
    saveDeepLink,
    clearDeepLink
  }
}
