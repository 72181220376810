import { APP_URL } from '../../pure-js/libs/Consts.js'
import { getFeatureFlagsFromUrl } from './libs/Common.js'

export type ConfigType = typeof defaultConfig

type PartialConfig = Partial<typeof defaultConfig>

let env: string | undefined = (import.meta as any).env?.VITE_ENVIRONMENT

if (process.env.NODE_ENV === 'test') env = 'test'
if (!env) env = 'prod'

export const defaultConfig = {
  // Config
  name: 'default',
  appUrl: 'http://localhost:5173',
  sentryDsn: 'https://ef4ccf693dc594c40afb04bfa911d1f3@o4506297061015552.ingest.us.sentry.io/4507678285103104',
  enableLogFirestoreQueryResult: false,
  enableErrorMessagesInNotifications: false,
  enableServiceUnavilablePage: false,
  enableLogFirebaseDBError: false,
  enableSentry: false,
  enableGoogleLogin: true,
  enableOverrideTeamId: true,
  enableOnboarding: true,
  enableShorterAssessment: false,
  enableUidOnProfilePage: true,
  enableSendTriggerReflectionEmailButton: false,
  enableInactiveTimeExperimental: false,
  enableAnalytics: false
}

export const local: PartialConfig = {
  ...defaultConfig,
  name: 'local',
  enableLogFirestoreQueryResult: true,
  enableErrorMessagesInNotifications: true,
  enableLogFirebaseDBError: true,
  enableOnboarding: true,
  enableShorterAssessment: true,
  enableSendTriggerReflectionEmailButton: false
}

const test: PartialConfig = {
  ...defaultConfig,
  name: 'test'
}

export const prod: PartialConfig = {
  ...defaultConfig,
  appUrl: APP_URL,
  name: 'prod',
  enableSentry: true
}

const configs = { local, prod, test }

let config = configs[env] || prod

config = { ...config, ...getFeatureFlagsFromUrl() }

if (config.enableLoggingOfConfig) console.log('Running app with config', config)

export default config as typeof defaultConfig
