import { getFigmaText } from '../../user-web/src/libs/TextRepository'
import { Enrollment } from '../types/GrooverTypes'
import { Texts } from './Texts'

export const obfuscateEmail = (email: string) => {
  const user = email.split('@')[0]
  const domain = email.split('@')[1]
  const domainParts = domain.split('.')
  const domainSuffix = domainParts[domainParts.length - 1]
  return `${user.slice(0, 2)}...@...${domainSuffix}`
}

export const obfuscateName = (name: string) => {
  return `${name[0]}...`
}

export const obfuscatePhoneNumber = (phoneNumber: string) => {
  return '*****'
}

export const obfuscateEnrollment = (enrollment: Enrollment) => {
  const obfuscatedMainUser = {
    ...enrollment.mainUser,
    name: obfuscateName(enrollment.mainUser.name),
    email: obfuscateEmail(enrollment.mainUser.email),
    phone: obfuscatePhoneNumber(enrollment.mainUser.phone)
  }

  const obfuscatedParnerUser = enrollment.partnerUser
    ? {
        ...enrollment.partnerUser,
        name: obfuscateName(enrollment.partnerUser.name),
        email: obfuscateEmail(enrollment.partnerUser.email),
        phone: obfuscatePhoneNumber(enrollment.partnerUser.phone)
      }
    : undefined

  return {
    ...enrollment,
    mainUser: obfuscatedMainUser,
    partnerUser: obfuscatedParnerUser
  }
}

export const getTotalUnpaidPaymentsAmount = (enrollment: Enrollment) => {
  const totalPrice = enrollment.priceDetails.reduce((total, pd) => {
    return total + pd.finalPrice
  }, 0)
  const totalPaid = enrollment.payments.reduce((total, payment) => {
    return total + payment.totalAmount
  }, 0)

  return totalPrice - totalPaid
}

export const getCurrencyFromEnrollment = (enrollment: Enrollment) => 'SEK'

export const getDiscountName = (type: string) => {
  if (!type) {
    return 'Unknown discount'
  }
  switch (type) {
    case 'student':
      return getFigmaText(Texts.componentsPricingControlStudentDiscountRow)
    case 'senior':
      return getFigmaText(Texts.componentsPricingControlSeniorDiscountRow) //'senior'
    case 'couples':
      return getFigmaText(Texts.componentsPricingControlCoupleDiscountRow)
    case 'multi_course_matrix':
      return getFigmaText(Texts.componentsPricingControlBundleDiscountRow)
    case 'combined':
      return getFigmaText(Texts.componentsPricingControlExtentionDiscountRow) //'Extended course'
    case 'member':
      return getFigmaText(Texts.componentsPricingControlMemberDiscountRow) //'Extended course'
    default:
      return 'Unknown discount'
  }
}
