import moment from 'dayjs'
import { ServerFirebaseUser } from '../types/ServerTypes.js'
import { BaseObject } from '../types/types.js'
import { createUniqueId } from './Common.js'
import invariant from 'invariant'

// eslint-disable-next-line prettier/prettier
export const toUpdatedObject = <T extends BaseObject>(object: T, u?: ServerFirebaseUser): T => ({ ...object, updatedAt: moment().format() });

type MapFunction = (item: any) => any

export const mapQueryResponse = (response: any, mapFn?: MapFunction) =>
  response.docs ? getCollectionData(response, mapFn) : getDocData(response, mapFn)

// Get array of doc data from collection
export const getCollectionData = (collection, mapFn?: MapFunction) =>
  collection.docs.map((doc) => getDocData(doc, mapFn))

export const getFirstDoc = (collection) => {
  invariant(collection.docs.length <= 1, 'Found Multiple documents')
  return collection.docs[0]?.data()
}
// Get doc data and merge doc.id
export function getDocData(doc, mapFn?: MapFunction) {
  // depending on what is using this (client/backend), doc.exists might be a property (backend) or function (client)
  if (!doc.exists || (typeof doc.exists === 'function' && !doc.exists())) return null
  const data = { id: doc.id, ...doc.data() }
  return mapFn ? mapFn(data) : data
}

export const toBaseObject = (): BaseObject => {
  const id = createUniqueId()
  return {
    id,
    createdAt: moment().format()
  }
}

export const assertNotMultipleDocs = <T>(docs: Array<T>, errorMessage = 'Found Multiple documents'): T | undefined => {
  invariant(docs.length <= 1, errorMessage)
  return docs[0]
}
