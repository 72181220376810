import styled from '@emotion/styled'
import { ButtonProps as MaterialButtonProps } from '@mui/material/Button'
import { DeprecatedTextKeys } from '../../../pure-js/libs/TextsDeprecated'
import { TextKeyWithOnlyText } from '../../../pure-js/types/Antiloop'
import { defaultSpacing } from '../enums/Spacings'
import { getFigmaText } from '../libs/TextRepository'
import { DeprecatedTexts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import ButtonBase, { ButtonBaseProps } from './ButtonBase'
import FigmaTextLoader from './FigmaTextLoader'

export type ButtonProps = {
  className?: string
  spacing?: string
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'white' | 'empty_state'
  textKey?: TextKeyWithOnlyText
  loading?: boolean
  text?: string
  color?: string
  verticalSpacing?: string
  isDisabled?: boolean
} & MaterialButtonProps

export default function ButtonPrimary(props: ButtonProps) {
  const { children, className, textKey, loading, verticalSpacing, variant, isDisabled, ...buttonProps } = props

  const buttonStyleTextKey =
    variant === 'outlined'
      ? DeprecatedTextKeys.txtButtonReadMore
      : DeprecatedTexts.onboardingOtpPageCtaPrimaryVerifyCode

  const color = variant === 'outlined' ? 'primary' : ('white' as any)

  return (
    <StyledButton
      className={className}
      variant="contained"
      verticalSpacing={verticalSpacing}
      color={color}
      spacing={defaultSpacing}
      {...buttonProps}
    >
      <Box align="center" fullWidth>
        {children || (
          <FigmaTextLoader
            textKey={buttonStyleTextKey}
            text={textKey ? getFigmaText(textKey) : undefined}
            loading={loading}
          />
        )}
      </Box>
    </StyledButton>
  )
}

const StyledButton = styled(ButtonBase)<ButtonBaseProps>``
