import styled from '@emotion/styled'

interface GoogleButtonProps {
  onClick: () => void
}

export const GoogleButton = ({ onClick }: GoogleButtonProps) => {
  return (
    <StyledButton onClick={onClick} type="button" className="gsi-material-button">
      <ButtonState className="gsi-material-button-state" />
      <ContentWrapper className="gsi-material-button-content-wrapper">
        <IconWrapper className="gsi-material-button-icon">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{ display: 'block' }}>
            <path
              fill="#EA4335"
              d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
            />
            <path
              fill="#4285F4"
              d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
            />
            <path
              fill="#FBBC05"
              d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
            />
            <path
              fill="#34A853"
              d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
            />
            <path fill="none" d="M0 0h48v48H0z" />
          </svg>
        </IconWrapper>
        <ButtonText className="gsi-material-button-contents">Logga in med Google</ButtonText>
      </ContentWrapper>
    </StyledButton>
  )
}

const StyledButton = styled.button`
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dadce0;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: 'Google Sans', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  max-width: 400px;

  &:hover {
    box-shadow:
      0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
  }
`

const ButtonState = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  opacity: 0;
  z-index: 1;

  ${StyledButton}:hover & {
    opacity: 0.9;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 2;
`

const IconWrapper = styled.div`
  height: 18px;
  margin-right: 8px;
  min-width: 18px;
  width: 18px;
`

const ButtonText = styled.span`
  line-height: 40px;
  margin-left: 6px;
  margin-right: 6px;
  font-weight: 500;
  color: #1f1f1f;
`
