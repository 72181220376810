/* eslint-disable camelcase */
import { Language } from '../types/Antiloop.js'

export const XXX = 'XXX'
export const PREVIOUS_OUTPUT = 'PREVIOUS_OUTPUT'
export const MONGO = 'MONGO'
export const GET = 'GET'
export const POST = 'POST'
export const PUT = 'PUT'
export const DELETE = 'DELETE'
export const EUROPE_STOCKHOLM = 'Europe/Stockholm'

export const HTTP_STATUS_CODE_INTERNAL_ERROR = 500
export const HTTP_STATUS_CODE_BAD_REQUEST = 400
export const HTTP_STATUS_CODE_NOT_FOUND = 404
export const HTTP_STATUS_CODE_UNAUTHORIZED = 401
export const HTTP_STATUS_CODE_OK = 200
export const HTTP_STATUS_CODE_REDIRECT = 302

export const HTTP_LOCATION_HEADER = 'Location'

export const HALF_SECOND = 500
export const ONE_SECOND = 1000
export const TWO_SECONDS = 2000
export const FIVE_SECONDS = ONE_SECOND * 5
export const TEN_SECONDS = ONE_SECOND * 10
export const ONE_MINUTE = 60 * 1000

export const DD_MMM = 'DD MMM'

export const TAB_KEYCODE = 13
export const ENTER_KEYCODE = 13
export const ESC_KEYCODE = 27

export const STATE = 'STATE'

export const COMMON_EMAIL_DOMAINS = [
  'gmail.com',
  'outlook.com',
  'yahoo.com',
  'hotmail.com',
  'inbox.com',
  'icloud.com',
  'mail.com',
  'me.com'
]

export const sv = 'sv'
export const en = 'en'
export const LANGUAGES = [sv, en]

export const LANGUAGES_OBJECT: { [property in Language]: string } = { sv: 'Svenska', en: 'English' }
export const LANGUAGES_OBJECT_MENU: { [property in Language]: string } = { sv: 'SWE', en: 'ENG' }
export const LANGUAGES_OBJECT_CREATE_AD: { [languageCode: string]: string } = {
  ...LANGUAGES_OBJECT,
  fr: 'Français',
  da: 'Dansk',
  no: 'Norsk',
  es: 'Español',
  de: 'Deutsch',
  ar: 'عربى',
  zh: '中文'
}

export const IGNORED_TEXT_KEYS: string[] = ['exampleText', 'ignoreText', 'genericText']

export const URL_REGEXP = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

// eslint-disable-next-line camelcase
export const YYYYMMDDHHmmss = 'YYYY-MM-DDTHH:mm:ss'
export const YYYYMMDD_HHmm = 'YYYY-MM-DD HH:mm'
export const YYYY_MM_DD = 'YYYY-MM-DD'
export const MMM_YYYY = 'MMM YYYY'
export const MMM_DD = 'MMM DD'
export const MMM_D = 'MMM D'
export const DMMMHHmm = 'D MMM HH:mm'

export const TEN_PERCENT = 0.1
export const TWENTY_PERCENT = 0.2

export const EUROPE_WEST_3 = 'europe-west3'
export const REGION = EUROPE_WEST_3

export const BLUR_KEYCODES = [TAB_KEYCODE, ENTER_KEYCODE]

export const MIN_CONTAINER_WIDTH = 600

export const USER_ID = 'userId'

export const MAX_QUERYABLE_ARRAY_LENGTH = 10

export const NO_OF_LETTERS_IN_SIGN_IN_CODE = 6

export const SNACK_BAR_PROPS: any = { maxSnack: 3, anchorOrigin: { vertical: 'top', horizontal: 'right' } }

export const APP_REVIEW_USER_EMAIL = 'APP_REVIEW_USER_EMAIL'

export const INVITE_MAIL_SUBJECT = 'You have been invited to join your colleagues at XXX'
export const LOGIN_WITH_CODE_SUBJECT = 'Gazelle Work confirmation code: XXX'

export const TERMS_AND_CONDITION_URL = ''

export const PRIVACY_POLICY_URL = ''

export const MAIL_TO_LINK = ''

export const FAQ_URL = ''

export const CONTACT_US_URL = ''

export const JAGUAR_SESSIONS_NOT_LOADING_UPDATE_INTERVAL = 1000

export const INFO_EMAIL_ADDRESS = ''

export const GAZELLE_ORG_ID = ''

export const YOUTUBE_URL = ''
export const INSTAGRAM_URL = ''
export const TWITTER_URL = ''

export const VALID_HTTPS_URL_REGEX = /^https:\/\/(?:[\w-]+\.)+[\w-]+(?:\/[\w-./?%&=]*)?$/

export const NOT_AUTHENTUCATED_ERROR_MESSAGE = 'Not authenticated'

export const GOOGLE_CLOUD_MAPS_API_KEY = ''

export const NOT_ENOUGH_INFO_ERROR = 'NotEnoughInfoError'

export const PRICING_URL_DEPRECATED = ''

export const IGNORED_KEYWORD_KEYS = ['meta']

export const USER_WIDGET_MENU_ID = 'UserWidgetMenu'

export const CAPTIONS_AD_LENGTH = 40

export const GC_FILE_PATH_DESIGN_TOKENS = `emu/design-tokens.tokens.json`
export const GC_FILE_PATH_DESIGN_SYSTEM_TOKENS = `emu/design-tokens.tokens.system.json`

export const MIN_NUMBER_OF_USERS_IN_TEAM = 4

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyDWC16er2TlEdIR80BRlno5s2hQJ9s0oJA',
  authDomain: 'courseportal-fb83a.firebaseapp.com',
  projectId: 'courseportal-fb83a',
  storageBucket: 'courseportal-fb83a.firebasestorage.app',
  messagingSenderId: '568099406793',
  appId: '1:568099406793:web:384e89989cec021626de18',
  measurementId: 'G-G5C380HZTD'
}

export const APP_URL = 'https://app.groover.com'

export const MAX_USERS_IN_TEAM = 4

export const MIN_SLOT_TIME_INTERVAL_MINUTES = 14

export const SUPER_ADMINS = ['edull165@gmail.com']
