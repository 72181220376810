import TextsJSON from '../../assets/texts.json'
import DeprecatedTextsJSON from '../../assets/deprecatedTexts.json'

export const DeprecatedTexts = DeprecatedTextsJSON
export const Texts = TextsJSON

export const HardCodedTexts = {
  errorMaintenanceDescription: {
    name: 'errorMaintenanceDescription',
    characters: 'We expect to be back shortly. Thanks for your patience.',
    style: {
      fontFamily: 'Inter',
      fontSize: '22px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(145, 143, 153, 1)'
    },
    pageName: 'Error Pages',
    figmaTextKeyName: 'TXT_ErrorMaintenanceDescription'
  },
  allTooltipText: {
    name: 'allTooltipText',
    characters: 'Anders Bäck',
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: '0.4000000059604645px',
      color: 'rgba(255, 255, 255, 1)'
    },
    pageName: '13 Tooltips',
    figmaTextKeyName: 'TXT_allTooltipText'
  },
  onboardingConnectAccountGoogleText: {
    name: 'onboardingConnectAccountGoogleText',
    characters: 'Google account',
    style: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 700,
      letterSpacing: 0,
      textDecoration: 'UNDERLINE',
      color: 'rgba(0, 0, 0, 1)'
    },
    pageName: 'Getting onboarded - Web onboarding',
    figmaTextKeyName: 'TXT_onboardingConnectAccountGoogleText'
  },
  onboardingConnectAccountMicrosoftText: {
    name: 'onboardingConnectAccountMicrosoftText',
    characters: 'Microsoft account',
    style: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 700,
      letterSpacing: 0,
      textDecoration: 'UNDERLINE',
      color: 'rgba(0, 0, 0, 1)'
    },
    pageName: 'Getting onboarded - Web onboarding',
    figmaTextKeyName: 'TXT_onboardingConnectAccountMicrosoftText'
  },
  onboardingConnectAccountEmailText: {
    name: 'onboardingConnectAccountEmailText',
    characters: 'Mail account',
    style: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 700,
      letterSpacing: 0,
      textDecoration: 'UNDERLINE',
      color: 'rgba(0, 0, 0, 1)'
    },
    pageName: 'Getting onboarded - Web onboarding',
    figmaTextKeyName: 'TXT_onboardingConnectAccountEmailText'
  },
  onboardingVerifyMicrosoftButtonText: {
    name: 'onboardingVerifyMicrosoftButtonText',
    characters: 'VERIFY MICROSOFT ACCOUNT',
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: '0.75px',
      color: 'rgba(255, 255, 255, 1)'
    },
    pageName: 'Getting onboarded - Web onboarding',
    figmaTextKeyName: 'TXT_onboardingVerifyMicrosoftButtonText'
  },
  onboardingVerifyGoogleButtonText: {
    name: 'onboardingVerifyGoogleButtonText',
    characters: 'VERIFY GOOGLE ACCOUNT',
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: '0.75px',
      color: 'rgba(255, 255, 255, 1)'
    },
    pageName: 'Getting onboarded - Web onboarding',
    figmaTextKeyName: 'TXT_onboardingVerifyGoogleButtonText'
  },
  onboardingLoginWithEmailButtonText: {
    name: 'onboardingLoginWithEmailButtonText',
    characters: 'LOGIN USING EMAIL',
    style: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: '0.25px',
      color: 'rgba(255, 255, 255, 1)'
    },
    pageName: 'Getting onboarded - Web onboarding',
    figmaTextKeyName: 'TXT_onboardingLoginWithEmailButtonText'
  },
  onboardingVerifyEmailButtonText: {
    name: 'onboardingVerifyEmailButtonText',
    characters: 'VERIFY EMAIL',
    style: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: '0.25px',
      color: 'rgba(255, 255, 255, 1)'
    },
    pageName: 'Getting onboarded - Web onboarding',
    figmaTextKeyName: 'TXT_onboardingVerifyEmailButtonText'
  }
}
