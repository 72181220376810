import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'

interface UploadResult {
  downloadUrl: string
  filePath: string
}

export const uploadFileToStorage = async (
  file: File,
  folder = 'uploads',
  onProgress?: (progress: number) => void
): Promise<UploadResult> => {
  try {
    // Get storage instance
    const storage = getStorage()

    // Create unique file path
    const timestamp = Date.now()
    const uniqueFileName = `${timestamp}-${file.name}`
    const filePath = `${folder}/${uniqueFileName}`

    // Create storage reference
    const storageRef = ref(storage, filePath)

    // Create upload task
    const uploadTask = uploadBytesResumable(storageRef, file)

    // Return promise that resolves when upload is complete
    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        // Progress callback
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          if (onProgress) {
            onProgress(progress)
          }
          console.log('Upload is ' + progress + '% done')
        },
        // Error callback
        (error) => {
          console.error('Upload failed:', error)
          reject(error)
        },
        // Success callback
        async () => {
          try {
            // Get download URL
            const downloadUrl = await getDownloadURL(storageRef)
            resolve({
              downloadUrl,
              filePath
            })
          } catch (error) {
            console.error('Failed to get download URL:', error)
            reject(error)
          }
        }
      )
    })
  } catch (error) {
    console.error('Upload setup failed:', error)
    throw error
  }
}
