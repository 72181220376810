import Box, { BoxProps } from './Box'

import styled from '@emotion/styled'
import { PropsWithChildren } from 'react'
import { MainColor } from '../../../pure-js/libs/Colors'
import ZIndices from '../enums/Zindices'
import { MENU_WIDTH } from '../libs/HardCodedSizes'

export const Layout: React.FC<
  PropsWithChildren<{
    enableFooter?: boolean
    enableMenu?: boolean
    fullHeight?: boolean
    menuZIndex?: ZIndices
    Container?: React.FC<BoxProps>
    enableRightBottomImage?: boolean
  }>
> = ({ children, enableMenu = false, menuZIndex = ZIndices.regular, Container = LayoutContinaner }) => {
  return (
    <Container fullWidth>
      <Box fullWidth direction="row">
        {enableMenu && (
          <Box position="relative" zIndex={menuZIndex}>
            {/* <Menu /> */}
          </Box>
        )}
        <Box fullWidth style={{ marginLeft: enableMenu ? MENU_WIDTH : 0 }}>
          {children}
        </Box>
      </Box>
    </Container>
  )
}

export const LayoutContinaner = styled(Box)`
  min-height: 100vh;
  background: radial-gradient(circle at 20% 30%, #2e004d, transparent 90%),
    radial-gradient(circle at 80% 20%, #0f4873, transparent 92%),
    radial-gradient(circle at 80% 80%, #b33600, transparent 91%);
  background-size: cover;
  background-position: center;
  overflow-x: hidden;
`
