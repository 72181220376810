export enum Collections {
  USERS = 'users',
  CLIENTS = 'clients',
  CLIENTS_PRIVATE = 'clients_private',
  COURSE_ADMINISTRATORS = 'course_administrators',
  ENROLLMENT_USERS = 'enrollment_users',
  ENROLLMENTS = 'enrollments',
  EVENTS = 'events',
  ACTIVITIES = 'activities',
  SWISH_PAYMENT_INTENT_STATUS = 'swish_payment_intent_status',
  SWISH_CONFIG = 'swish_config',
  STRIPE_CONFIG = 'stripe_config'
}
