import { useEffect, useRef } from 'react'
import config from '../Config'

type Status = 'idle' | 'loading' | 'success' | 'error'

export type ReducerState<T> = {
  status: Status
  data?: T
  _data?: { [_id: string]: T }
  error?: Error
  isLoading: boolean
}
export type Options = {
  fnName: string
  mapFn?: (item: any) => any // should be used in complex migrations only
  enabled?: boolean
  enableLogOfPermissionFailures?: boolean
}
export const MISSING_PERMISSIONS = 'Missing or insufficient permissions'
// Reducer for hook state and actions
export const reducer = (state, action) => {
  switch (action.type) {
    case 'idle':
      return { ...state, status: 'idle', isLoading: true }
    case 'loading':
      return { ...state, status: 'loading', isLoading: true }
    case 'success':
      return { status: 'success', data: action.payload, error: undefined, isLoading: false }
    case 'error':
      return { ...state, status: 'error', error: action.payload, isLoading: false }
    default:
      throw new Error('invalid action')
  }
}

export const log = (...args) => config.enableLogFirestoreQueryResult && console.log(...args)
// Usage

export function useMemoCompare<T>(next: T, compare: (obj1: T | undefined, obj2: T) => boolean) {
  // Ref for storing previous value
  const previousRef = useRef<T | undefined>(undefined)
  const previous = previousRef.current
  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next)
  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next
    }
  })
  // Finally, if equal then return the previous value
  return isEqual ? previous : next
}
