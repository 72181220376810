import React from 'react'
import { FormGroup, FormControlLabel, Checkbox, InputLabel } from '@mui/material'
import Box from './Box'
import styled from '@emotion/styled'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import { formatText } from '../../../user-web/src/libs/TextRepository'
import { getDiscountName } from '../../../pure-js/libs/EnrollmentHelper'

interface Discount {
  id: string
  type: string
  name: string
  value?: number
  calculationType?: 'percentage' | 'amount'
  courseDiscounts?: Array<{
    course: number
    discount: number
  }>
}

interface DiscountSelectorProps {
  discounts: Discount[]
  selectedDiscountIds: string[]
  onDiscountToggle: (discountId: string) => void
}

const formatDiscountValue = (discount: Discount): string => {
  if (!discount.calculationType || !discount.value) return ''
  return discount.calculationType === 'percentage' ? `${discount.value}%` : `${(discount.value / 100).toFixed(0)} SEK`
}

const DiscountSelector: React.FC<DiscountSelectorProps> = ({ discounts, selectedDiscountIds, onDiscountToggle }) => {
  return (
    <Container direction="column" fullWidth>
      <InputLabel size="small" style={{ fontSize: '14px', marginBottom: '-6px', marginLeft: '22px' }}>
        {getFigmaText(Texts.adminGeneralInputFieldsActivityDiscounts)}
      </InputLabel>
      <DiscountInput direction="column" fullWidth>
        <FormGroup>
          {discounts.map((discount) => (
            <Box fullWidth key={discount.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedDiscountIds.includes(discount.id)}
                    onChange={() => onDiscountToggle(discount.id)}
                  />
                }
                label={
                  <DiscountLabel direction="row" fullWidth justify="space-between" style={{ marginBottom: 0 }}>
                    <span>{getDiscountName(discount.type)}</span>
                    {discount.type !== 'multi_course_matrix' && (
                      <DiscountValue>{formatDiscountValue(discount)}</DiscountValue>
                    )}
                  </DiscountLabel>
                }
              />
              {discount.type === 'multi_course_matrix' && discount.courseDiscounts && (
                <SubDiscountInfo>
                  {discount.courseDiscounts.map((cd) => (
                    <div key={`${discount.id}-${cd.course}`}>
                      {formatText(getFigmaText(Texts.componentsPublicDiscountCssPublicActivityDiscountSubRule), [
                        `${cd.course} : ${discount.calculationType === 'amount' ? (cd.discount / 100).toFixed(0) + ' SEK' : cd.discount + '%'}`
                      ])}
                    </div>
                  ))}
                </SubDiscountInfo>
              )}
            </Box>
          ))}
        </FormGroup>
      </DiscountInput>
    </Container>
  )
}

const Container = styled(Box)`
  margin-top: 16px;
`

const DiscountInput = styled(Box)`
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  margin: 12px 8px;
  padding: 8px;
  width: 100%;

  .MuiFormGroup-root {
    width: 100%;
  }

  .MuiFormControlLabel-root {
    width: 100%;
    margin: 0;
  }

  .MuiCheckbox-root {
    padding: 2px;
  }
`

const DiscountLabel = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const DiscountValue = styled('span')`
  color: rgba(255, 255, 255, 0.7);
  margin-left: 16px;
`

const SubDiscountInfo = styled(Box)`
  margin: 0;
  margin-left: 32px;
  font-size: 0.85em;
  color: rgba(255, 255, 255, 0.6);
`

export default DiscountSelector
