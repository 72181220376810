import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import useAppState from '../hooks/useAppState'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Activity, Client } from '../../../pure-js/types/GrooverTypes'
import { getActivity, getClient, upsertActivity } from '../libs/DBApiHandler'
import {
  TextField,
  Button,
  CircularProgress,
  InputLabel,
  Checkbox,
  MenuItem,
  FormGroup,
  FormControlLabel
} from '@mui/material'
import Box from '../components/Box'
import UserMenu from '../components/UserMenu'
import { White } from '../../../pure-js/libs/Colors'
import styled from '@emotion/styled'
import toast from 'react-hot-toast'
import JoditEditor from 'jodit-react'

import '../resources/css/EditActivity.css'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import FigmaText from '../components/FigmaText'
import { getDiscountName } from '../../../pure-js/libs/EnrollmentHelper'
import { formatText } from '../../../user-web/src/libs/TextRepository'
import DiscountSelector from '../components/DiscountSelector'

const EditActivityPage: React.FC = () => {
  const { state } = useAppState()
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [client, setClient] = useState<Client | null>(null)
  const [activity, setActivity] = useState<Activity | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [userCanSave, setUserCanSave] = useState<boolean>(false)
  const editor = useRef(null)

  const navigate = useNavigate()
  const { activityId } = useParams<{ activityId: string }>()

  const auth = getAuth()
  const currentUser = auth.currentUser

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: activity?.description || 'Beskrivning',
      buttons: ['bold', 'italic', 'underline', 'eraser', '|', 'ul', 'ol', '|', 'link', 'video'],
      toolbarAdaptive: false,
      buttonsMD: ['bold', 'link', 'video'],
      buttonsSM: ['bold', 'link', 'video'],
      buttonsXS: ['bold', 'link', 'video'],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultMode: 1,
      controls: {
        video: {
          tooltip: 'Insert YouTube Video',
          // TODO: Lägg en custom kontroll för länkar som är lokaliserad
          popup: (editor: any, current: any, control: any) => {
            const form = editor.create.fromHTML(
              '<div style="position:relative;z-index:10000;width:100%; min-width: 300px; max-width:500px;">' +
                '<form class="jodit-form" style="background:#fff;color:#000;padding:8px;width:100%;border-radius:8px;box-sizing:border-box;">' +
                '<div class="jodit-form__group">' +
                '<label style="display:block;margin-bottom:8px;font-size:14px;">YouTube URL</label>' +
                '<input class="jodit-form__input" placeholder="Klistra in videolänken här" type="text" ' +
                'style="color:#000;background:#fff;padding:12px;width:100%;border:1px solid #ccc;border-radius:4px;font-size:16px;box-sizing:border-box;"/>' +
                '</div>' +
                '<div class="jodit-form__group" style="margin-top:16px;text-align:center;">' +
                '<button class="jodit-button" type="submit" ' +
                'style="background:#1976d2;color:#fff;padding:12px 24px;border:none;border-radius:4px;cursor:pointer;font-size:16px;min-width:100px;">' +
                'Ladda upp video</button>' +
                '</div>' +
                '</form>' +
                '</div>'
            )
            // TODO: Localize the form

            form.addEventListener('submit', (e: Event) => {
              e.preventDefault()
              const input = form.querySelector('input')
              const url = input?.value || ''
              if (url) {
                // Convert URL to embed format if needed
                const videoId = url.match(
                  /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/i
                )?.[1]
                if (videoId) {
                  editor.s.insertHTML(
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`
                  )
                  // Close all popups
                  editor.e.fire('hidePopup')
                  editor.e.fire('closeAllPopups')
                  try {
                    editor.dialog.close()
                  } catch (e) {
                    // Handle potential errors silently
                  }
                }
              }
            })

            return form
          }
        }
      },
      theme: 'light',
      toolbarButtonSize: 'middle' as const,
      statusBar: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      height: 300,
      allowResizeY: false
    }),
    [isLoading, isSaving, window.innerWidth]
  )

  const sanitizeLinks = (content: string): string => {
    // Ensure all links are absolute
    return content.replace(/href="(?!http)/g, 'href="https://')
  }

  const fetchClient = async (clientId: string) => {
    try {
      const clientData = await getClient(clientId)
      if (!clientData) {
        toast.error('Client not found', {
          duration: 2000,
          position: 'top-center'
        })
      }
      return clientData
    } catch (err) {
      console.error('Failed to fetch client:', err)
      toast.error('Failed to fetch client data', {
        duration: 2000,
        position: 'top-center'
      })
    }
  }

  const fetchActivity = async () => {
    try {
      setIsLoading(true)
      if (!activityId) {
        setError('Activity ID is missing')
        return
      }

      const activityData = await getActivity(activityId)
      if (!activityData) {
        toast.error('Activity not found', {
          duration: 2000,
          position: 'top-center'
        })
        return
      }

      // Fetch client data after successful activity fetch
      if (activityData.clientId) {
        const clientData = await fetchClient(activityData.clientId)
        setClient(clientData ?? null)
      }

      setActivity(activityData)
    } catch (err) {
      toast.error('Failed to fetch data!', {
        duration: 2000,
        position: 'top-center'
      })
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const checkUserCanSave = async () => {
    const activityClientId = activity?.clientId || ''
    const userClientIds = (state.user?.authCustomClaims?.clientIds as string[]) || []
    setUserCanSave(userClientIds.includes(activityClientId) && currentUser !== null)
  }

  useEffect(() => {
    checkUserCanSave()
  }, [state, activity, currentUser])

  useEffect(() => {
    fetchActivity()
    checkUserCanSave()
  }, [activityId])

  const handleReturn = () => {
    navigate(-1) // Navigate back
  }

  const handleInputChange = (field: keyof Activity, value: any) => {
    if (activity) {
      setActivity({ ...activity, [field]: value })
    }
  }

  const handleSave = async () => {
    if (!activity) return

    try {
      setError(null)
      setIsSaving(true)
      const sanitizedDescription = sanitizeLinks(activity.description || '')
      const updatedActivity = { ...activity, description: sanitizedDescription }

      await upsertActivity(updatedActivity) // Call your Upsert function here
      setIsSaving(false)
      toast.success('Saved successfully!', {
        duration: 2000,
        position: 'top-center'
      })
    } catch (err) {
      toast.error('Failed to save activity!', {
        duration: 2000,
        position: 'top-center'
      })
      console.error(err)
    } finally {
      setIsSaving(false)
    }
  }

  const handleDiscountToggle = (discountId: string) => {
    if (!activity) return

    const currentDiscounts = activity.discountIds || []
    const newDiscounts = currentDiscounts.includes(discountId)
      ? currentDiscounts.filter((id) => id !== discountId)
      : [...currentDiscounts, discountId]

    handleInputChange('discountIds', newDiscounts)
  }

  if (isLoading) {
    return (
      <MainContainer fullHeight fullWidth>
        <Box alignSelf="center" fullPadding style={{ color: White }}>
          Loading...
        </Box>
      </MainContainer>
    )
  }

  if (!activity) {
    console.log('No activity found', activity)
    return (
      <MainContainer fullHeight fullWidth>
        <Box alignSelf="center" fullPadding style={{ color: White }}>
          {getFigmaText(Texts.adminGeneralErrorNoActivityFound)}
        </Box>
      </MainContainer>
    )
  }
  if (error) {
    return (
      <MainContainer fullHeight fullWidth>
        <Box alignSelf="center" fullPadding style={{ color: White }}>
          {error}
        </Box>
      </MainContainer>
    )
  }

  return (
    <MainContainer fullHeight fullWidth className="mainContainer">
      <UserMenu
        onClientIdSelect={() => {
          console.warn('add onClientIdSelect for EditActivityPage')
        }}
      />
      <PageContainer fullWidth className="PageContainer">
        <Box fullWidth>
          <ArrowBackIcon onClick={handleReturn} style={{ cursor: 'pointer' }} />
        </Box>
        <Box position="relative" fullWidth>
          {isSaving && (
            <SpinnerOverlay fullWidth>
              <CircularProgress />
            </SpinnerOverlay>
          )}

          <form>
            <Box fullWidth align="flex-end">
              <Box>
                <Button
                  href={`https://app.coursely.se/activity/${activity.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={<OpenInNewIcon />}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    background: 'rgba(255, 255, 255, 0.1)',
                    color: White,
                    padding: '4px 8px',
                    borderRadius: '4px',
                    margin: '8px 0',
                    gap: '8px',
                    '&:hover': {
                      background: 'rgba(255, 255, 255, 0.3)',
                      color: White
                    }
                  }}
                >
                  {getFigmaText(Texts.adminGeneralCtactaOpenPublicActivityPage)}
                </Button>
              </Box>
            </Box>
            <Box id="SectionContainer" fullWidth gap="16px">
              <Section id="Section: General Info" fullWidth>
                <SectionHeader textKey={Texts.adminGeneralInputFieldsSectionGeneral} />
                <Box direction="row" gap="10px" fullWidth className="boxColumn">
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityTitel)}
                    value={activity ? activity.name : ''}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                  />
                  <CustomTextField
                    select
                    label={getFigmaText(Texts.adminGeneralStatusLabel)}
                    value={activity?.status || ''}
                    onChange={(e) => handleInputChange('status', e.target.value)}
                    sx={{ maxWidth: '230px' }}
                  >
                    <MenuItem value="active">{getFigmaText(Texts.adminGeneralEnumsStatusStatusActive)}</MenuItem>
                    <MenuItem value="draft">{getFigmaText(Texts.adminGeneralEnumsStatusStatusDraft)}</MenuItem>
                    <MenuItem value="cancelled">{getFigmaText(Texts.adminGeneralEnumsStatusStatusCancelled)}</MenuItem>
                    <MenuItem value="archived">{getFigmaText(Texts.adminGeneralEnumsStatusStatusArchived)}</MenuItem>
                  </CustomTextField>
                  <CustomTextField
                    select
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityType)}
                    value={activity?.type || ''}
                    onChange={(e) => handleInputChange('type', e.target.value)}
                    sx={{ maxWidth: '230px' }}
                  >
                    <MenuItem value="Occasion">{getFigmaText(Texts.adminGeneralEnumsActivityTypeOccasion)}</MenuItem>
                    <MenuItem value="Course">{getFigmaText(Texts.adminGeneralEnumsActivityTypeCourse)}</MenuItem>
                  </CustomTextField>
                </Box>
                <Box className="joditWrapper" fullWidth>
                  <Box left spacing="14px">
                    <InputLabel size="small" style={{ fontSize: '14px' }}>
                      {getFigmaText(Texts.adminGeneralInputFieldsActivityDescription)}
                    </InputLabel>
                  </Box>

                  <JoditEditor
                    className="customTextFieldJodit"
                    ref={editor}
                    value={activity?.description}
                    config={
                      {
                        ...config,
                        height: 300,
                        allowResizeY: true,
                        minHeight: 200,
                        maxHeight: 800,
                        addNewLine: false
                      } as any
                    }
                    onBlur={(newContent) => handleInputChange('description', newContent)}
                  />
                </Box>
              </Section>
              <Section id="Section: Schedule And Duration" fullWidth>
                <SectionHeader textKey={Texts.adminGeneralInputFieldsSectionScheduleAndDuration} />
                <Box direction="row" gap="10px" fullWidth className="boxColumn">
                  <CustomTextField
                    select
                    label={getFigmaText(Texts.adminGeneralPeriodLabel)}
                    value={activity?.period || ''}
                    onChange={(e) => handleInputChange('period', e.target.value)}
                    sx={{ maxWidth: '230px' }}
                  >
                    <MenuItem value="VT 2025">VT 2025</MenuItem>
                  </CustomTextField>
                </Box>
                <Box direction="row" gap="10px" fullWidth className="boxColumn">
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityCity)}
                    value={activity?.city || ''}
                    onChange={(e) => handleInputChange('city', e.target.value)}
                  />
                  <CustomTextField
                    select
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityLocation)}
                    value={activity?.locationId || ''}
                    onChange={(e) => handleInputChange('locationId', e.target.value)}
                  >
                    {client?.locations.map((location) => (
                      <MenuItem id={location.id} value={location.id}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityPlace)}
                    value={activity?.place || ''}
                    onChange={(e) => handleInputChange('place', e.target.value)}
                  />
                </Box>
                <Box direction="row" gap="10px" fullWidth className="boxColumn">
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityStartdate)}
                    type="date"
                    value={activity?.startDate}
                    onChange={(e) => handleInputChange('startDate', e.target.value)}
                  />
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityTime)}
                    type="time"
                    value={activity?.time}
                    onChange={(e) => {
                      const timePattern = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/
                      if (e.target.value === '' || timePattern.test(e.target.value)) {
                        handleInputChange('time', e.target.value)
                      }
                    }}
                    inputProps={{
                      step: 300 // 5 min intervals
                    }}
                  />
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityLength)}
                    type="number"
                    value={activity?.length}
                    onChange={(e) => handleInputChange('length', parseInt(e.target.value) || 0)}
                  />
                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityNoOfOccations)}
                    type="number"
                    value={activity?.noOfOccasions}
                    onChange={(e) => handleInputChange('noOfOccasions', parseInt(e.target.value) || 0)}
                  />
                </Box>
              </Section>
              <Box direction="row" gap="10px" fullWidth className="boxColumn">
                <Section id="Section: Target Audience" fullWidth>
                  <SectionHeader textKey={Texts.adminGeneralInputFieldsSectionTargetAudience} />
                  <Box direction="row" gap="10px" fullWidth className="boxColumn">
                    <CustomTextField
                      label={getFigmaText(Texts.adminGeneralInputFieldsActivityStyle)}
                      value={activity ? activity.style : ''}
                      onChange={(e) => handleInputChange('style', e.target.value)}
                    />
                    <CustomTextField
                      label={getFigmaText(Texts.adminGeneralInputFieldsActivityLevel)}
                      value={activity ? activity.level : ''}
                      onChange={(e) => handleInputChange('level', e.target.value)}
                    />
                  </Box>
                </Section>
                <Section id="Section: Pricing and Discounts" fullWidth>
                  <SectionHeader textKey={Texts.adminGeneralInputFieldsSectionPricingAndDiscounts} />

                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityPrice)}
                    type="number"
                    value={activity ? activity.price / 100 : undefined}
                    onChange={(e) => handleInputChange('price', parseFloat(e.target.value) * 100 || 0)}
                  />
                  {client?.discounts && (
                    <DiscountSelector
                      discounts={client.discounts}
                      selectedDiscountIds={activity?.discountIds || []}
                      onDiscountToggle={handleDiscountToggle}
                    />
                  )}
                </Section>
              </Box>
              <Box direction="row" gap="10px" fullWidth className="boxColumn">
                <Section id="Section: Capacity and Enrollment" fullWidth>
                  <SectionHeader textKey={Texts.adminGeneralInputFieldsSectionCapacityAndEnrollment} />
                  <Box direction="row" gap="10px" fullWidth className="boxColumn">
                    <CustomTextField
                      label={getFigmaText(Texts.adminGeneralInputFieldsActivityMaxRegistrations)}
                      type="number"
                      value={activity?.maxAllowedParticipants}
                      onChange={(e) => handleInputChange('maxAllowedParticipants', parseInt(e.target.value) || 0)}
                    />
                    <CustomTextField
                      label={getFigmaText(Texts.adminGeneralInputFieldsActivityMaxDiscrepency)}
                      type="number"
                      value={activity?.maxAllowedUnbalance}
                      onChange={(e) => handleInputChange('maxAllowedUnbalance', parseInt(e.target.value) || 0)}
                    />
                  </Box>
                  <Box direction="row" gap="10px" fullWidth align="center">
                    <Checkbox
                      checked={activity?.approvalRequired || false}
                      onChange={(e) => handleInputChange('approvalRequired', e.target.checked)}
                    />
                    <CheckBoxLabel textKey={Texts.adminGeneralInputFieldsActivityRequireEnrollmentApproval} />
                  </Box>
                </Section>
                <Section id="Section: Grouping and Metadata" fullWidth>
                  <SectionHeader textKey={Texts.adminGeneralInputFieldsSectionGroupingAndMetadata} />

                  <CustomTextField
                    label={getFigmaText(Texts.adminGeneralInputFieldsActivityGroup)}
                    value={activity ? activity.group : 'test'}
                    onChange={(e) => handleInputChange('group', e.target.value)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Section>
              </Box>
            </Box>
          </form>
          <StickyFooter direction="row" fullWidth>
            <Button variant="contained" color="primary" onClick={handleSave} disabled={!userCanSave || isSaving}>
              {getFigmaText(Texts.adminGeneralCtactaSave)}
            </Button>
          </StickyFooter>
        </Box>
      </PageContainer>
    </MainContainer>
  )
}

export default EditActivityPage

const CustomTextField = styled(TextField)`
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  width: 100%;
  margin: 12px 8px;
`

const CheckBoxLabel = styled(FigmaText)`
  margin: 0;
  top: 0;
`

const SectionHeader = styled(FigmaText)`
  padding-bottom: 12px;
  width: 100%;
`

const Section = styled(Box)`
  background: rgba(0, 0, 0, 0.4);
  padding: 8px 16px;
  border-radius: 4px;
`

const MainContainer = styled(Box)`
  display: inline-flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding: 10px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  background: radial-gradient(circle at 20% 30%, #2e004d, transparent 90%),
    radial-gradient(circle at 80% 20%, #0f4873, transparent 92%),
    radial-gradient(circle at 80% 80%, #b33600, transparent 91%);
  background-color: #000000; /* Black fallback for vibrancy */
  color: #ffffff;
  overflow: hidden;
  animation: gradient-animation 5s ease infinite;
`

const PageContainer = styled(Box)`
  padding: 16px;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  overflow-y: auto;
  height: calc(100vh - 20px); // Account for MainContainer padding

  /* Add custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }
`
const SpinnerOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  z-index: 1;
`

const StickyFooter = styled(Box)`
  position: sticky;
  bottom: 0;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 12px 16px;
  z-index: 1;
  width: 100%;
  border-radius: 4px;
`
