import styled from '@emotion/styled'
import FigmaText from './FigmaText'

export const Flexbox = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`

export const LinkStyles = `
  cursor: pointer;
  :hover {
    text-decoration: underline;
  };
`

const NoWrapFigmaTextStyles = `
  white-space: nowrap;
`
export const NoWrapFigmaText = styled(FigmaText)`
  ${NoWrapFigmaTextStyles}
`
