import React, { FC } from 'react'
import Box from './Box'

interface ActivityListEmptyStateProps {
  loading: boolean
  hasResults: boolean
}

const ActivityListEmptyState: FC<ActivityListEmptyStateProps> = ({ loading, hasResults }) => {
  if (loading) {
    return (
      <Box fullWidth align="center">
        <div className="spinner"></div>
      </Box>
    )
  }
  if (!hasResults) {
    return <div className="no-results">No results available</div>
  }
  return null
}

export default ActivityListEmptyState
