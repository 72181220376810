enum RoutePath {
  ROOT = '/',
  SANDBOX = '/sandbox',
  IFRAME = '/iframe',
  DEMOPAGE = '/demo',
  PAY = '/pay/:enrollmentId',
  SWISH_PAY = '/swish/:enrollmentId',
  PAY_SUCCESS = '/pay-success',
  REGISTER = '/register/:activityId',
  DASHBOARD = '/dashboard',
  ENROLLMENTS = '/enrollments/:clientId/:activityId',
  PROFILE = '/profile',
  INVITE = '/invite',
  EDIT_ACTIVITY = '/editActivity/:activityId',
  ACTIVITY_DETAIL = '/activity/:activityId',
  EDIT_CLIENT = '/editClient/:clientId',
  PUBLIC_ACTIVITIES = '/activities/:clientId'
}

export default RoutePath
