import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom' // Import useNavigate from react-router-dom
import { debounce } from 'lodash' // Import debounce from lodash
import { Edit, OpenInNew } from '@mui/icons-material'
import '../resources/css/ActivityList.css' // Import CSS
import { Activity, Enrollment } from '../../../pure-js/types/GrooverTypes'
import Box from './Box'
import { getEnrollmentCountForActivity } from '../utils/enrollmentUtils'
import ActivityListHeader from './ActivityListHeader'
import ActivityListFilteredEnrollments from './ActivityListFilteredEnrollments'
import ActivityListEmptyState from './ActivityListEmptyState'
import { Menu, MenuItem, IconButton, TextField } from '@mui/material'
import { getStatusTextKey, handleRegistrationClick, highlightText } from '../libs/ActivityListHelper'
import styled from '@emotion/styled'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import ButtonBlack from './ButtonBlack'
import { upsertActivity } from '../libs/DBApiHandler'
import toast from 'react-hot-toast'
import { AccessTime, CalendarToday, LocationOn, ControlPointDuplicate } from '@mui/icons-material'
import FigmaText from './FigmaText'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import StatusChip from './StatusChip'

interface ActivityListProps {
  activities: Activity[]
  activitiesLoaded: boolean
  enrollments: Enrollment[]
  clientId: string
}

/* Main Component */
const ActivityList: React.FC<ActivityListProps> = ({ activities, activitiesLoaded, enrollments, clientId }) => {
  const [filterText, setFilterText] = useState('')
  const [filteredActivities, setFilteredActivities] = useState<Activity[]>(activities)
  const [filteredEnrollments, setFilteredEnrollments] = useState<Enrollment[]>(enrollments)
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedActivity, setSelectedActivity] = useState<Activity | null>(null)
  const [isDuplicating, setIsDuplicating] = useState(false)
  const [groupBy, setGroupBy] = useState<'style' | 'city' | 'startdate' | 'type' | 'status'>(
    () => (localStorage.getItem('activityListGroupBy') as 'style' | 'city' | 'startdate' | 'type' | 'status') || 'style'
  )

  const navigate = useNavigate() // Initialize useNavigate

  const debouncedFilter = useCallback(
    debounce((text: string) => {
      setFilteredActivities(
        activities.filter((registration) => registration.name.toLowerCase().includes(text.toLowerCase()))
      )
      const filteredEnrs = enrollments.filter(
        (enr) =>
          enr.mainUser.name.toLowerCase().includes(text.toLowerCase()) ||
          enr.mainUser.email.toLowerCase().includes(text.toLowerCase()) ||
          enr.partnerUser?.name?.toLowerCase().includes(text.toLowerCase()) ||
          enr.partnerUser?.email?.toLowerCase().includes(text.toLowerCase())
      )
      setFilteredEnrollments(text ? filteredEnrs : [])
      setLoading(false)
    }, 300),
    [activities, enrollments]
  )

  useEffect(() => {
    setLoading(true) // Start loading immediately when the filter text changes
    if (activitiesLoaded) debouncedFilter(filterText)
    return () => {
      debouncedFilter.cancel()
    }
  }, [filterText, debouncedFilter, activitiesLoaded])

  useEffect(() => {
    localStorage.setItem('activityListGroupBy', groupBy)
  }, [groupBy])

  const formatDateSwedish = (date: string) => {
    const formatted = new Date(date).toLocaleString('sv-SE', { month: 'long', year: 'numeric' })
    return formatted.charAt(0).toUpperCase() + formatted.slice(1)
  }

  const getLocalizedActivityType = (type: string) => {
    switch (type) {
      case 'Course':
        return getFigmaText(Texts.adminGeneralEnumsActivityTypeCourse)
      case 'Occasion':
        return getFigmaText(Texts.adminGeneralEnumsActivityTypeOccasion)
      default:
        return '-'
    }
  }

  const groupActivityKeys = useMemo(() => {
    const keys = Object.keys(
      filteredActivities.reduce((acc: { [key: string]: Activity[] }, activity) => {
        let groupKey = activity[groupBy] || '-'
        if (groupBy === 'startdate') {
          groupKey = activity.startDate ? formatDateSwedish(activity.startDate) : '-'
        } else if (groupBy === 'type') {
          groupKey = getLocalizedActivityType(activity.type)
        } else if (groupBy === 'status') {
          groupKey = getStatusTextKey(activity.status)
        }
        if (!acc[groupKey]) {
          acc[groupKey] = []
        }
        acc[groupKey].push(activity)
        return acc
      }, {})
    )

    // Don't sort dates alphabetically, but sort everything else
    if (groupBy === 'startdate') {
      return keys
    }
    return keys.sort((a, b) => a.localeCompare(b))
  }, [filteredActivities, groupBy])

  const hasResults = groupActivityKeys.length > 0 || filteredEnrollments.length > 0
  const createNewActivity = async () => {
    try {
      const newActivityId = crypto.randomUUID()
      const newActivity: Activity = {
        id: newActivityId,
        createdAt: new Date().toISOString(),
        type: 'Course',
        style: 'Generell',
        name: 'New Activity',
        clientId: clientId,
        startDate: '',
        description: 'New activity',
        activityType: 'Couple',
        price: 0,
        approvalRequired: false,
        allowDropin: false,
        status: 'draft',
        length: 60,
        discountIds: [],
        maxAllowedParticipants: 30,
        period: '',
        noOfOccasions: 1,
        time: ''
      }
      const response = await upsertActivity(newActivity)
      toast.success(getFigmaText(Texts.adminGeneralInfoTextsInfoMessageNewActivitySuccess), {
        duration: 2000,
        position: 'top-center'
      })
      navigate(`/editActivity/${newActivityId}`)
    } catch (error) {
      toast.success(getFigmaText(Texts.adminGeneralInfoTextsInfoMessageNewActivityFail), {
        duration: 2000,
        position: 'top-center'
      })
      console.error('error creating activity', error)
    }
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, activity: Activity) => {
    setAnchorEl(event.currentTarget)
    setSelectedActivity(activity)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setSelectedActivity(null)
  }

  const handleDuplicate = async () => {
    if (selectedActivity && !isDuplicating) {
      setIsDuplicating(true)
      try {
        const newActivityId = 'test' + crypto.randomUUID()
        const duplicatedActivity = {
          ...selectedActivity,
          id: newActivityId,
          createdAt: new Date().toISOString(),
          name: `${selectedActivity.name} (Copy)`
        }
        await upsertActivity(duplicatedActivity)
        // Update the activities list with the new activity
        setFilteredActivities((prev) => [...prev, duplicatedActivity])
        toast.success(getFigmaText(Texts.adminGeneralInfoTextsInfoMessageNewActivitySuccess))
        handleMenuClose()
        navigate(`/editActivity/${newActivityId}`)
      } catch (error) {
        toast.error(getFigmaText(Texts.adminGeneralInfoTextsInfoMessageNewActivitySuccess))
        console.error('error duplicating activity', error)
      } finally {
        setIsDuplicating(false)
      }
    }
  }

  return (
    <div className="activity-list">
      <Box fullWidth direction="row" justify="space-between" align="center" gap="16px" className="boxColumn">
        <ActivityListHeader
          filterText={filterText}
          setFilterText={setFilterText}
          hasResults={hasResults}
          isLoading={loading}
        />
        <Box direction="row" fullWidth align="center" width="fit-content">
          <CustomTextField
            select
            label={getFigmaText(Texts.adminGeneralInputFieldsGroupByLabel)}
            value={groupBy}
            onChange={(e) => setGroupBy(e.target.value as 'style' | 'city' | 'startdate' | 'status')}
          >
            <MenuItem value="style">{getFigmaText(Texts.adminGeneralEnumsGroupByGroupByDanceType)}</MenuItem>
            <MenuItem value="type">{getFigmaText(Texts.adminGeneralEnumsGroupByGroupByActivityType)}</MenuItem>
            <MenuItem value="city">{getFigmaText(Texts.adminGeneralEnumsGroupByGroupByCity)}</MenuItem>
            <MenuItem value="startdate">{getFigmaText(Texts.adminGeneralEnumsGroupByGroupByMonth)}</MenuItem>
            <MenuItem value="status">{getFigmaText(Texts.adminGeneralEnumsGroupByGroupByStatus)}</MenuItem>
          </CustomTextField>
          <ButtonBlack onClick={() => createNewActivity()}>
            {getFigmaText(Texts.adminGeneralCtactaCreateNewActivity)}
          </ButtonBlack>
        </Box>
      </Box>

      <ActivityListEmptyState loading={loading} hasResults={hasResults} />
      {groupActivityKeys.length > 0
        ? groupActivityKeys.map((key) => (
            <div key={key}>
              <h2>{key}</h2>
              {filteredActivities
                .filter((activity) => {
                  if (groupBy === 'startdate') {
                    const activityMonth = activity.startDate ? formatDateSwedish(activity.startDate) : '-'
                    return activityMonth === key
                  }
                  if (groupBy === 'type') {
                    return getLocalizedActivityType(activity.type) === key
                  }
                  if (groupBy === 'status') {
                    return getStatusTextKey(activity.status) === key
                  }
                  return activity[groupBy] === key
                })
                .map((activity) => (
                  <ActivityRow key={activity.id} direction="column" fullWidth type={activity.type} gap="8px">
                    <Box direction="row" align="center" fullWidth justify="space-between">
                      <div onClick={() => handleRegistrationClick(navigate, activity)}>
                        {highlightText(activity.name, filterText)}
                      </div>

                      <Box direction="row" gap="4px" spacing="4px" align="center">
                        <StatusChip status={activity.status} />
                        <IconButton onClick={(e) => handleMenuOpen(e, activity)}>
                          <MoreVertIcon />
                        </IconButton>
                      </Box>
                      <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                        {[
                          {
                            icon: <Edit />,
                            text: getFigmaText(Texts.adminGeneralCtactaEditActivity),
                            onClick: () => {
                              handleMenuClose()
                              navigate(`/editActivity/${selectedActivity?.id}`)
                            }
                          },
                          {
                            icon: <OpenInNew />,
                            text: getFigmaText(Texts.adminGeneralCtactaOpenPublicActivityPage),
                            onClick: () => {
                              handleMenuClose()
                              window.open(`https://app.coursely.se/activity/${selectedActivity?.id}`, '_blank')
                            }
                          },
                          {
                            icon: <ControlPointDuplicate />,
                            text: getFigmaText(Texts.adminGeneralCtactaDuplicateActivity),
                            onClick: handleDuplicate
                          }
                        ].map(({ icon, text, onClick }, index) => (
                          <MenuItem
                            key={index}
                            onClick={onClick}
                            disabled={text === getFigmaText(Texts.adminGeneralCtactaDuplicateActivity) && isDuplicating}
                          >
                            {React.cloneElement(icon, {
                              style: { marginRight: '8px', width: '20px', height: '20px', fill: 'black' }
                            })}
                            {text}
                          </MenuItem>
                        ))}
                      </StyledMenu>
                    </Box>
                    <Box fullWidth align="center" direction="row" justify="space-between" className="boxColumn">
                      <Box direction="row" gap="8px">
                        <OpenEnrollmentCTA
                          direction="row"
                          align="center"
                          onClick={() => handleRegistrationClick(navigate, activity)}
                        >
                          {getFigmaText(Texts.adminGeneralCtactaOpenRegistrations)}
                          <EnrollmentCount className="activity-enrollment-count" align="center">
                            {getEnrollmentCountForActivity(enrollments, activity.id)}
                          </EnrollmentCount>
                        </OpenEnrollmentCTA>
                        <ActivityEnrollmentStats direction="row" gap="8px" align="center">
                          <FigmaText textKey={Texts.adminGeneralEnumsActivityUserTypeLeader} />
                          <EnrollmentTypeCount>
                            {getEnrollmentCountForActivity(enrollments, activity.id, 'leader')}
                          </EnrollmentTypeCount>
                          <FigmaText textKey={Texts.adminGeneralEnumsActivityUserTypeFollower} />
                          <EnrollmentTypeCount>
                            {getEnrollmentCountForActivity(enrollments, activity.id, 'follower')}
                          </EnrollmentTypeCount>
                          <FigmaText textKey={Texts.adminGeneralEnumsActivityUserTypeBoth} />
                          <EnrollmentTypeCount>
                            {getEnrollmentCountForActivity(enrollments, activity.id, 'both')}
                          </EnrollmentTypeCount>
                        </ActivityEnrollmentStats>
                      </Box>

                      <ActivityMetadataContainer direction="row" gap="8px">
                        {[
                          { icon: CalendarToday, value: activity.startDate },
                          { icon: AccessTime, value: activity.time },
                          { icon: LocationOn, value: activity.city, hideOnMobile: true }
                        ]
                          .filter((item) => item.value)
                          .map((item, index) => {
                            const IconComponent = item.icon
                            const Wrapper = item.hideOnMobile ? ActivityMetadataHideOnMobile : ActivityMetadata

                            return (
                              <Wrapper direction="row" key={index}>
                                <IconComponent
                                  sx={{
                                    color: 'rgba(255, 255, 255, 0.4)',
                                    height: '16px'
                                  }}
                                />
                                {item.value}
                              </Wrapper>
                            )
                          })}
                      </ActivityMetadataContainer>
                    </Box>
                  </ActivityRow>
                ))}
            </div>
          ))
        : null}
      {filteredEnrollments.length > 0 ? (
        <ActivityListFilteredEnrollments
          filteredEnrollments={filteredEnrollments}
          highlightText={highlightText}
          filterText={filterText}
          handleRegistrationClick={handleRegistrationClick}
          navigate={navigate}
        />
      ) : null}
    </div>
  )
}

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    // Optional: customize menu items
    .MuiMenuItem-root {
      color: #000;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
`

const ActivityEnrollmentStats = styled(Box)``

const EnrollmentTypeCount = styled.p`
  font-size: 14px;
  font-weight: 700;
  margin: 0;
`

const activityThemes = {
  Course: {
    background: 'linear-gradient(to right, #2b1055, #7596de1c)',
    hoverBackground: 'linear-gradient(to right, #3c1577, #2b4cad)',
    border: '#7617d0',
    hoverBorder: '#ae7df7'
  },
  Occasion: {
    background: 'linear-gradient(to right, rgba(7, 103, 18, 0.54), rgba(9, 111, 21, 0.35))',
    hoverBackground: 'linear-gradient(to right, rgba(7, 103, 18, 0.60), rgba(9, 111, 21, 0.60))',
    border: '#176d17',
    hoverBorder: '#7df77d'
  },
  default: {
    background: 'linear-gradient(to right, #2b1055, #7596de1c)',
    hoverBackground: 'linear-gradient(to right, #3c1577, #2b4cad)',
    border: '#7617d0',
    hoverBorder: '#ae7df7'
  }
}
const CustomTextField = styled(TextField)`
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  width: 100%;
  min-width: 150px;
  margin: 12px 8px;
  max-width: 200px;
  & .MuiFormHelperText-root {
    padding-left: 20px; /* Adjust as needed */
  }
`

const ActivityRow = styled(Box)<{ type?: string }>`
  align-items: left;
  background: ${({ type }) => activityThemes[type || 'default'].background};
  border: 1px solid ${({ type }) => activityThemes[type || 'default'].border};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  margin-bottom: 8px;
  padding: 12px;
  transition: all 0.3s ease;

  &:hover {
    background: ${({ type }) => activityThemes[type || 'default'].hoverBackground};
    border: 1px solid ${({ type }) => activityThemes[type || 'default'].hoverBorder};
  }
`

const EnrollmentCount = styled(Box)`
  background: rgba(0, 0, 0, 0.57);
  border-radius: 50%;
  color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 0 4px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease;
`

const OpenEnrollmentCTA = styled(Box)`
  cursor: pointer;
  font-size: 14px;
  &:hover {
    border-radius: 4px;
    color: rgb(255, 170, 0);

    ${EnrollmentCount} {
      border: 2px solid rgb(199, 139, 20);
    }
  }
`

const ActivityMetadataContainer = styled(Box)`
  border-radius: 4px;
  font-size: 14px;
`

const ActivityMetadata = styled(Box)`
  align-items: center;
`

const ActivityMetadataHideOnMobile = styled.p`
  margin: 0px;
  margin: 0px 8px;
  @media (max-width: 768px) {
    display: none;
  }
`

export default ActivityList
