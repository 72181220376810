import { NavigateFunction } from 'react-router-dom'
import { Activity } from '../../../pure-js/types/GrooverTypes'
import { Texts } from '../../../pure-js/libs/Texts'
import { getFigmaText } from './TextRepository'

export const highlightText = (text: string, highlight: string): JSX.Element | string => {
  if (!highlight) return text
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} style={{ backgroundColor: '#ffeb3b', color: 'black' }}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </>
  )
}

export const handleRegistrationClick = (navigate: NavigateFunction, activity: Activity) => {
  navigate(`/enrollments/${activity.clientId}/${activity.id}`, { state: { registration: activity } })
}

export const getStatusTextKey = (status: string) => {
  switch (status.toLowerCase()) {
    case 'active':
      return getFigmaText(Texts.adminGeneralEnumsStatusStatusActive)
    case 'archived':
      return getFigmaText(Texts.adminGeneralEnumsStatusStatusArchived)
    case 'cancelled':
      return getFigmaText(Texts.adminGeneralEnumsStatusStatusCancelled)
    case 'draft':
      return getFigmaText(Texts.adminGeneralEnumsStatusStatusDraft)
    case 'published':
      return getFigmaText(Texts.adminGeneralEnumsStatusStatusPublished)
    default:
      return getFigmaText(Texts.adminGeneralEnumsStatusStatusDraft)
  }
}
