import styled from '@emotion/styled'
import React from 'react'
import { TextKey } from '../../../pure-js/types/Antiloop'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { getFigmaText } from '../libs/TextRepository'
import { DeprecatedTexts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import { ButtonProps } from './ButtonPrimary'
import FigmaTextLoader from './FigmaTextLoader'

const ButtonWhite: React.FC<ButtonProps> = (props) => {
  const { children, className, loading, verticalSpacing, onClick } = props
  return (
    <StyledButton className={className} spacing={verticalSpacing} pointer onClick={onClick as any}>
      <Box align="center" fullWidth>
        {children || (
          <FigmaTextLoader
            textKey={DeprecatedTexts.onboardingAsessmentQuestionsCtaNextQuestion}
            text={props.text || getFigmaText(props.textKey as TextKey)}
            loading={loading}
          />
        )}
      </Box>
    </StyledButton>
  )
}

const StyledButton = styled(Box)<{ spacing?: string }>`
  padding-left: ${({ spacing }) => spacing || bigSpacing};
  padding-right: ${({ spacing }) => spacing || bigSpacing};
  padding-top: ${smallSpacing};
  padding-bottom: ${smallSpacing};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  background-color: #0000003b;
  border: 1px solid rgba(255, 255, 255, 0.17);
  border-radius: 6px;
  white-space: nowrap;

  &:hover {
    background-color: rgba(78, 78, 78, 0.31);
  }
`

export default ButtonWhite
