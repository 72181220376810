import styled from '@emotion/styled'
import Box from './Box'
import { getStatusTextKey } from '../libs/ActivityListHelper'

interface StatusChipProps {
  status: string
}

const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
  return <StyledChip status={status}>{getStatusTextKey(status)}</StyledChip>
}

const StyledChip = styled(Box)<{ status: string }>`
  padding: 2px 8px;
  border-radius: 8px;
  min-width: fit-content; // Prevents shrinking below content size
  font-size: 14px;
  font-weight: 500;
  color: white;
  text-transform: capitalize;
  background-color: ${({ status }) => {
    switch (status.toLowerCase()) {
      case 'draft':
        return '#786400'
      case 'active':
      case 'published':
        return '#208800'
      case 'archived':
        return '#4B4B4B'
      case 'cancelled':
        return '#8A0E0E'
      default:
        return '#786400'
    }
  }};
  color: white;
  @media (max-width: 768px) {
    display: none;
  }
`

export default StatusChip
