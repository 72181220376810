// @flow
import moment from 'dayjs'
import { createUniqueId } from '../../../pure-js/libs/Common.js'
import { BaseObject, Id } from '../../../pure-js/types/types.js'
import { State } from '../hooks/useAppState.js'

export const toBaseObject = (
  { user: { id: userId } }: State,
  id: Id = createUniqueId(),
  now = moment().format()
): BaseObject => ({
  id,
  createdAt: now
})
