import invariant from 'invariant'
import { NO_OF_LETTERS_IN_SIGN_IN_CODE, ONE_SECOND } from './Consts.js'

export const groupBy = <T>(xs: Array<T>, key: keyof T): { [key: string]: Array<T> } => {
  return xs.reduce(
    (rv, x: any) => {
      // eslint-disable-next-line prettier/prettier
      (rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    },
    {} as { [key: string]: Array<T> }
  )
}

export const mean = (arr: number[]) => arr.reduce((a, b) => a + b, 0) / arr.length

export const createUniqueId = () => Math.random().toString(36).substring(2, 11)

export const createGuid = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)

  return `${s4()}-${s4()}-${s4()}-${s4()}-${s4()}`
}

export const getArray = (length: number): Array<number> =>
  // eslint-disable-next-line prefer-spread
  Array.apply(null, { length } as any).map(Number.call, Number) as number[]

export const asObject = <T>(arr: Array<T>, key: keyof T): { [key: string]: T } =>
  arr.reduce(
    (a, b) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      a[b[key]] = b
      return a
    },
    {} as { [key: string]: T }
  )

export const removeDuplicates = <T>(arr: Array<T>, key: keyof T): T[] => asArray(asObject(arr, key))

export function removeDuplicateStrings<T>(arr: T[] = []): T[] {
  return [...new Set(arr.filter((a) => !!a) || [])]
}

export const asArray = <T>(obj: { [key: string]: T }): T[] => Object.keys(obj).map((_id) => obj[_id])

export const isNumber = (n: unknown) => typeof n === 'number' && !isNaN(n)
export const isString = (n: unknown) => typeof n === 'string'

export const getRandomValue = (floor: number, ceil: number) => {
  const diff = ceil - floor
  return floor + Math.random() * diff
}

export const getMean = (arr: number[]) => {
  if (arr.length === 0) return 0
  return arr.reduce((a, b) => a + b, 0) / arr.length
}

export const formatPercent = (n: number) => `${n.toFixed()}%`

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const getDomain = (email: string) => {
  const domain = email.split('@')[1]
  invariant(domain, 'Cant parse email: %s', email)
  return domain.toLowerCase()
}

export const getEmailForFirebaseUser = (user: any): string => {
  return user.email || user.providerData?.[0]?.email
}

export const mapCssHeightToNumber = (cssHeightInPixels?: string) =>
  (!!cssHeightInPixels && parseInt(cssHeightInPixels.replace('px', ''))) || 0

export function getDecimals(num: number) {
  if (Math.floor(num.valueOf()) === num.valueOf()) return 0
  return num.toString().split('.')[1]?.length || 0
}

export const toPx = (cssString: string) => parseInt(cssString.replace('px', ''))

export function getObjectOrBoolForJSON(str = ''): object | boolean {
  try {
    return JSON.parse(str)
  } catch (e) {
    return false
  }
}

export function getObjectForJSON(str = ''): object | undefined {
  try {
    return JSON.parse(str)
  } catch (e) {
    return
  }
}

export const getChunks = <T>(array: Array<T>, chunkSize: number): Array<Array<T>> => {
  let i
  let j
  let temparray = [] as T[]
  const res = [] as T[][]
  for (i = 0, j = array.length; i < j; i += chunkSize) {
    temparray = array.slice(i, i + chunkSize)
    res.push(temparray)
  }

  return res
}

export function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}

export function getAllKeys(obj, opts: { ignoredKeys: string[] } = { ignoredKeys: [] }) {
  const { ignoredKeys = [] } = opts
  let keys: string[] = []

  // eslint-disable-next-line guard-for-in
  for (const key in obj) {
    if (ignoredKeys.includes(key)) return keys
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      keys.push(key)
      keys = keys.concat(getAllKeys(obj[key]))
    } else {
      keys.push(key)
    }
  }

  return keys
}

export const runInSequence = (funcs: Array<() => Promise<any>>) => {
  let result = Promise.resolve() as any
  return Promise.all(
    funcs.map((task) => {
      result = result.then(() => task())
      return result
    })
  )
}

export function getLoggableObject(obj, opts: any = {}) {
  const { ignoreKeys = [] } = opts
  if (typeof obj !== 'number' && !obj) {
    return undefined
  }

  if (typeof obj !== 'object') {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map((o) => getLoggableObject(o, opts))
  }

  return Object.entries(obj)
    .filter(([_, v]) => v !== null)
    .filter(([k]) => !ignoreKeys.includes(k))
    .reduce((acc: any, [k, v]) => ({ ...acc, [k]: getLoggableObject(v, opts) }), {})
}

export function delay(ms: number = ONE_SECOND) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function getRandomCode(): string {
  return getArray(NO_OF_LETTERS_IN_SIGN_IN_CODE)
    .map((x) => Math.min(Math.round(Math.random() * 10), 9))
    .join('')
}
