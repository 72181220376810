// @flow
import { createTheme } from '@mui/material'
import { MainColor, SecondayColor, TertiaryColor, White } from '../../../pure-js/libs/Colors'

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } })

export const DefaultMaterialTheme = {
  palette: {
    primary: createColor(MainColor),
    secondary: createColor(SecondayColor),
    tertiary: createColor(TertiaryColor),
    white: createColor(White)
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#000'
      }
    }
  }
}

const theme = createTheme(DefaultMaterialTheme)

export default theme
