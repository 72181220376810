import styled from '@emotion/styled'
import { MainColor } from '../../../pure-js/libs/Colors'
import { DeprecatedTexts } from '../../../pure-js/libs/Texts'
import { SignInViewProps } from '../../../pure-js/types/SignInTypes'
import { BorderRadixes } from '../enums/BorderRadixes'
import { reallyBigSpacing, smallSpacing } from '../enums/Spacings'
import { LoginConatinerWidth } from '../libs/HardCodedSizes'
import Box from './Box'
import FigmaText from './FigmaText'
import { Layout } from './Layout'
import SignInEnterCodeForm from './SignInEnterCodeForm'
import { SignInLandingLogo } from './SignInLandingCourselyLogo'

export default function SignInEnterCode(props: SignInViewProps) {
  return (
    <Layout>
      <Box fullWidth align="center">
        <Box top spacing={reallyBigSpacing}>
          <SignInLandingLogo />
        </Box>
        <Box top spacing={reallyBigSpacing}>
          <FigmaText textKey={DeprecatedTexts.onboardingOtpPageOtpPageHeader} />
        </Box>
        <Box top spacing={reallyBigSpacing}>
          <Container fullWidth>
            <Box fullPadding fullWidth direction="row" justify="space-between" align="center">
              <Box pointer onClick={props.onClickBack}>
                {/**  <FigmaImageContainer imageKey={Images.backIcon} /> **/}
              </Box>
              <Box></Box>
              <Box></Box>
            </Box>
            <Box align="center" right left spacing={reallyBigSpacing} fullWidth>
              <Box top spacing={smallSpacing} fullWidth>
                <SignInEnterCodeForm {...props} />
              </Box>
            </Box>
            <Box top spacing={smallSpacing} />
          </Container>
        </Box>
      </Box>
    </Layout>
  )
}

const Container = styled(Box)`
  background-color: ${MainColor};
  border-radius: ${BorderRadixes.big};
  width: ${LoginConatinerWidth}px;
`
