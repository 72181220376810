import { User as AuthUser } from 'firebase/auth'
import { DevIdsQueryParams } from '../../../pure-js/types/QueryParamTypes'
import { User } from '../../../pure-js/types/User'
import useQueryParams from '../libs/useQueryParams'
import * as QueryHooks from './QueryHooks'
import useAppState from './useAppState'
import useFirebaseUser from './useFirebaseUser'
import { isSuperUserEmail } from '../../../pure-js/libs/isSuperUser'

export function useUser(id?: string) {
  const { state } = useAppState()
  const qp = useQueryParams<DevIdsQueryParams>()
  const { data: firebaseUser } = useFirebaseUser()
  // Ensure uid is a string
  const uid = id || getUidFromQueryParams(qp, firebaseUser) || state.user.id

  const query = QueryHooks._useUser(uid)

  // Provide a fallback if query.data is not available
  query.data = query.data || (state.user as User)

  return query as { data: User; isLoading: boolean }
}

export const getUidFromQueryParams = (qp: DevIdsQueryParams, firebaseUser?: AuthUser) => {
  if (firebaseUser && isSuperUserEmail(firebaseUser.email) && qp.uid) {
    return qp.uid
  }

  return undefined
}
