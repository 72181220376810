/* eslint-disable prettier/prettier */
import { CloudFunction } from '../../../pure-js/enums/CloudFunction.js'
import { CreateAuthenticationTokenForLoginTokenResponse } from '../../../pure-js/types/CreateAuthenticationTokenForLoginTokenResponse.js'
import { Enrollment } from '../../../pure-js/types/GrooverTypes.js'
import { GetEnrollmentsForPeriodRequest, GetEnrollmentsRequest, SendEnrollmentEmailRequest, SendEnrollmentEmailResponse } from '../../../pure-js/types/QueryParamTypes.js'
import { SignUpUserRequest } from '../../../pure-js/types/User.js'
import { CreateLoginTokenRequest, InviteUsersRequest, LoginWithCodeToken } from '../../../pure-js/types/types.js'
import { State } from '../hooks/useAppState.js'
import { callFunction } from './CloudFunctionsApiHandlerHelper.js'

export const createAuthenticationTokenForLoginToken = (req: LoginWithCodeToken, state: State): Promise<CreateAuthenticationTokenForLoginTokenResponse> => callFunction(CloudFunction.createAuthenticationTokenForLoginToken, state, req)

export const createLoginWithCodeToken = (req: CreateLoginTokenRequest, state: State): Promise<CreateLoginTokenRequest> => callFunction(CloudFunction.createLoginWithCodeToken, state, req)

export const inviteUsers = (req: InviteUsersRequest, state: State): Promise<string> => callFunction(CloudFunction.inviteUsers, state, req)

export const signUpUser = (req: SignUpUserRequest, state: State): Promise<string> => callFunction(CloudFunction.signUpUser, state, req)

export const getEnrollmentsForClient = (req: GetEnrollmentsRequest, state: State): Promise<Enrollment[]> => callFunction(CloudFunction.getEnrollmentsForClient, state, req)

export const upsertEnrollment = (req: Enrollment, state: State): Promise<Enrollment> => callFunction(CloudFunction.upsertEnrollment, state, req)

export const getEnrollmentsForPeriod = (req: GetEnrollmentsForPeriodRequest, state: State): Promise<Enrollment[]> => callFunction(CloudFunction.getEnrollmentsForPeriod, state, req)

export const sendEnrollmentEmail = (req: SendEnrollmentEmailRequest, state: State): Promise<SendEnrollmentEmailResponse> => callFunction(CloudFunction.sendEnrollmentEmail, state, req)
