import { Enrollment, Activity } from '../../../pure-js/types/GrooverTypes'

export const formatPayment = (payment?: number) => {
  if (payment === undefined || payment === null) {
    return 'N/A'
  }
  return Math.round(payment / 100)
}

// Return all revenue or only confirmed revenue for a registration
export const getAllRevenueForRegistration = (enrollments: Enrollment[], onlyConfirmed: boolean) => {
  const filterEnrollments = enrollments.filter(
    (enrollment) => enrollment.payments && (!onlyConfirmed || enrollment.status === 'confirmed')
  )

  const totalRevenue = filterEnrollments.reduce((total, enrollment) => {
    const enrollmentRevenue = enrollment.priceDetails.reduce(
      (paymentTotal, priceDetails) => paymentTotal + priceDetails.finalPrice,
      0
    )
    return total + enrollmentRevenue
  }, 0)

  return formatPayment(totalRevenue)
}

export const getAverageActiveCourseFillRate = (enrollments: Enrollment[], activities: Activity[]): string => {
  const totalSpots = activities.reduce((sum, activity) => sum + activity.maxAllowedParticipants, 0)
  const totalRegistrations = getAllEnrollmentsCount(enrollments)
  const averageFillRate = (totalRegistrations / totalSpots) * 100

  return averageFillRate.toFixed(0)
}

export const getAllPaidRevenueForRegistration = (enrollments: Enrollment[]) => {
  return formatPayment(
    enrollments.reduce((total, enrollment) => {
      return total + getEnrollmentPaidAmount(enrollment)
    }, 0)
  )
}

export const getPaidRegistrationRatio = (enrollments: Enrollment[]): string => {
  const calculatePaidAmount = (enrollment: Enrollment): number => {
    return enrollment.payments
      .filter((payment) => payment.type === 'pay')
      .reduce((sum, payment) => sum + payment.totalAmount, 0)
  }

  const calculateConfirmedAmount = (enrollment: Enrollment): number => {
    return enrollment.status === 'confirmed'
      ? enrollment.priceDetails.reduce((sum, price) => sum + price.finalPrice, 0)
      : 0
  }

  const totalPaidAmount = enrollments.reduce((sum, enrollment) => sum + calculatePaidAmount(enrollment), 0)

  const totalConfirmedAmount = enrollments.reduce((sum, enrollment) => sum + calculateConfirmedAmount(enrollment), 0)

  if (totalConfirmedAmount === 0) return '0%'

  const ratio = (totalPaidAmount / totalConfirmedAmount) * 100
  return `${ratio.toFixed(0)}%`
}

export const getAllEnrollmentsCount = (enrollments: Enrollment[]): number => {
  return enrollments.reduce((total, attendee) => total + 1 + (attendee.partnerUser ? 1 : 0), 0)
}

export const getEnrollmentPrice = (enrollment: Enrollment): number =>
  enrollment.priceDetails.reduce((total, pd) => total + pd.finalPrice, 0)

const getEnrollmentPaidAmount = (enrollment: Enrollment): number =>
  enrollment.payments?.reduce((total, payment) => {
    return total + payment.totalAmount
  }, 0)

export const isEnrollmentPaid = (enrollment: Enrollment): boolean =>
  getEnrollmentPrice(enrollment) <= getEnrollmentPaidAmount(enrollment)

export const getEnrollmentCountForActivity = (enrollments: Enrollment[], id: string, type?: string): number => {
  return enrollments
    .filter((e) => e.activities.some((a) => a.id === id))
    .reduce((count, e) => {
      if (type) {
        const mainCount = e.mainUser.type === type ? 1 : 0
        const partnerCount = e.partnerUser && e.partnerUser.type === type ? 1 : 0
        return count + mainCount + partnerCount
      }
      return count + (e.partnerUser ? 2 : 1)
    }, 0)
}

export const getDirectionBasedOnScreenWidth = () => {
  return window.innerWidth <= 768 ? 'column' : 'row'
}
