import React, { useEffect, useState, useMemo, useRef } from 'react'
import { getClient, upsertClient } from '../libs/DBApiHandler'
import Box from '../components/Box'
import UserMenu from '../components/UserMenu'
import { White } from '../../../pure-js/libs/Colors'
import styled from '@emotion/styled'
import toast from 'react-hot-toast'
import { Client } from '../../../pure-js/types/GrooverTypes'
import { uploadFileToStorage } from '../libs/StorageHelper'
import { useParams } from 'react-router-dom'
import { COURSELY_PUBLIC_MEDIA_URL } from '../../../pure-js/web'

const EditClientPage: React.FC = () => {
  const [client, setClient] = useState<Client | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [downloadUrl, setDownloadUrl] = useState<string>('')
  const [filePath, setFilePath] = useState<string>('')

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!client) return
    const file = event.target.files?.[0]
    if (!file) return

    try {
      const result = await uploadFileToStorage(file, `logos/${client?.id}`, (progress) => setUploadProgress(progress))

      setDownloadUrl(result.downloadUrl)
      setFilePath(result.filePath)
      upsertClient({ id: client.id, logoFullUrl: `${COURSELY_PUBLIC_MEDIA_URL}/${result.filePath}` }) // save logo with public URL to client
      console.log('File uploaded successfully:', result)
    } catch (error) {
      console.error('Upload failed:', error)
    }
  }

  const { clientId } = useParams<{ clientId: string }>()

  const fetchClient = async () => {
    if (!clientId) return
    try {
      const clientData = await getClient(clientId)
      if (!clientData) {
        return toast.error('Client not found', { duration: 2000, position: 'top-center' })
      }
      setClient(clientData)
    } catch (err) {
      console.error('Failed to fetch client:', err)
      toast.error('Failed to fetch client data', { duration: 2000, position: 'top-center' })
    }
  }

  useEffect(() => {
    fetchClient()
    setIsLoading(false)
  }, [clientId])

  if (isLoading) {
    return (
      <MainContainer fullHeight fullWidth>
        <Box alignSelf="center" fullPadding style={{ color: White }}>
          Loading...
        </Box>
      </MainContainer>
    )
  }

  return (
    <MainContainer fullHeight fullWidth className="mainContainer">
      <UserMenu
        onClientIdSelect={() => {
          console.warn('add onClientIdSelect for EditActivityPage')
        }}
      />
      <PageContainer fullWidth className="PageContainer">
        <h1>Edit Client</h1>
        <div>
          Client logo
          <div>
            {client?.logoFullUrl ? (
              <img src={client.logoFullUrl} alt="Client Logo" width="200" style={{ marginBottom: '16px' }} />
            ) : 'no logo set in logoFullUrl'}
          </div>
        </div>
        <div>
          <label htmlFor="logo">Upload new logo</label>
          <input type="file" onChange={handleFileUpload} id="logo" />
          {uploadProgress > 0 && <progress value={uploadProgress} max="100" />}
          {downloadUrl && (
            <div>
              <h3>Logo uploaded successfully!</h3>
              <ul>
                <li>storage path: {filePath}</li>
                <li>media link <a href={`${COURSELY_PUBLIC_MEDIA_URL}/${filePath}`} target="_blank" rel="noopener noreferrer">
                  {COURSELY_PUBLIC_MEDIA_URL}/{filePath}
                </a>
                </li>
                <li>storage link <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
                  {downloadUrl}
                </a>
                </li>
              </ul>
              <p>
                <img src={downloadUrl} alt="Uploaded Logo" width="200" />
              </p>
            </div>
          )}
        </div>
      </PageContainer>
    </MainContainer >
  )
}

const MainContainer = styled(Box)`
  display: inline-flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding: 10px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  background: radial-gradient(circle at 20% 30%, #2e004d, transparent 90%),
    radial-gradient(circle at 80% 20%, #0f4873, transparent 92%),
    radial-gradient(circle at 80% 80%, #b33600, transparent 91%);
  background-color: #000000; /* Black fallback for vibrancy */
  color: #ffffff;
  overflow: hidden;
  animation: gradient-animation 5s ease infinite;
`

const PageContainer = styled(Box)`
  padding: 16px;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  overflow-y: auto;
  height: calc(100vh - 20px); // Account for MainContainer padding

  /* Add custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }
`

export default EditClientPage
