import React from 'react'
import { Enrollment } from '../../../pure-js/types/GrooverTypes'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import lucideCircleCheckGreen from '../resources/svg/lucide_circle-check_green.svg'
import { formatPayment, getEnrollmentPrice, isEnrollmentPaid } from '../utils/enrollmentUtils'
import styled from '@emotion/styled'

interface PaymentStatusProps {
  enrollment: Enrollment
}

const PaymentStatus: React.FC<PaymentStatusProps> = ({ enrollment }) => {
  if (enrollment.status === 'confirmed' && !isEnrollmentPaid(enrollment)) {
    return (
      <EnrollmentPricePending>
        <RadioButtonUncheckedIcon style={{ fontSize: '1rem' }} />{' '}
        {' ' + formatPayment(getEnrollmentPrice(enrollment)) + ' SEK'}
      </EnrollmentPricePending>
    )
  } else if (enrollment.status === 'pending') {
    return (
      <EnrollmentPriceNotApproved>{formatPayment(getEnrollmentPrice(enrollment)) + ' SEK'}</EnrollmentPriceNotApproved>
    )
  }

  if (isEnrollmentPaid(enrollment)) {
    return (
      <EnrollmentPricePaid>
        <img src={lucideCircleCheckGreen} alt="Paid" style={{ width: '1rem', height: '1rem' }} />{' '}
        {formatPayment(getEnrollmentPrice(enrollment))}
        {' SEK'}
      </EnrollmentPricePaid>
    )
  }

  return null
}

const EnrollmentPriceNotApproved = styled.p`
  color: #999;
  margin: 0;
`

const EnrollmentPricePending = styled.p`
  color: #fff;
  margin: 0;
`

const EnrollmentPricePaid = styled.p`
  color: rgb(29, 179, 32);
  margin: 0;
`

export default PaymentStatus
